import axios from "axios";
import { useState } from "react";
import { apiBaseUrl, apiBaseUrl2, apiUrl, pageRoutes } from "../important/commanFunction";
import moment from "moment/moment";
import FullPageLoading from "./FullPageLoading";
import { useNavigate } from "react-router-dom";
import messageShow from "../important/message.json"

const BusinessSelected = ({ details, remove, showMessage }) => {
  const { sessionToken, id } = JSON.parse(localStorage.getItem("userData"));
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const logOut = () => {
    localStorage.removeItem("userData");
    navigate(pageRoutes.login);
  }

  const sendApprove = () => {
    const button = document.getElementById("businessApproveButtom");

    setLoading(true);
    const headers = {
      Accept: "*/*",
      "Content-Type": "application/json",
    };

    if (id && sessionToken) {
      axios
        .post(
          apiBaseUrl + apiUrl.performAdminAction,
          {
            adminActionType: "APPROVE",
            cardId: details?.userBusinessId,
            cardType: "BUSINESS",
            comments: "string",
            adminUserId: id,
            sessionToken,
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log(response);
          if (response?.data?.responseType === "SUCCESS") {
            if (button) {
              button.click();
            }
            showMessage({
              success: true,
              msg: "Successfully Business Approve !",
            });
            remove(details?.userBusinessId);
          }
        })
        .catch((error) => {
          showMessage({
            success: false,
            msg: messageShow.error.something_wrong,
          });
          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false);
          setMessage("");
        });
    } else {
      logOut()
    }
  };

  const sendReject = () => {
    const button = document.getElementById("businessRejectButtom");
    setLoading(true);
    const headers = {
      Accept: "*/*",
      "Content-Type": "application/json",
    };

    if (id && sessionToken) {
      axios
        .post(
          apiBaseUrl + apiUrl.performAdminAction,
          {
            adminActionType: "DISAPPROVE",
            cardId: details?.userBusinessId,
            cardType: "BUSINESS",
            comments: "string",
            adminUserId: id,
            sessionToken,
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log(response);
          if (response?.data?.responseType === "SUCCESS") {
            if (button) {
              button.click();
            }
            remove(details?.userBusinessId);
            showMessage({
              success: true,
              msg: "Successfully Business Reject !",
            });
          }
        })
        .catch((error) => {
          showMessage({
            success: false,
            msg: messageShow.error.something_wrong,
          });
          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false);
          setMessage("");
        });
    } else {
      logOut()
    }
  };

  return (
    <>
      {loading && <FullPageLoading />}
      <div className="col-md-8 business-details">
        <div>
          <div className="image-container">
            {details?.businessImageLocation ? <img src={details?.businessImageLocation} style={{ width: "50%" }} /> : <p>Image Not Found</p>}

            <div>
              <h3>Status : {details?.businessVerificationStatus}</h3>
              <h3>Id : {details?.userBusinessId}</h3>
              <h2>Enabled : <span
                className="badge bg-primary-subtle text-primary-emphasis rounded-pill">{details?.userBusinessEnabled ? "true" : 'false'} </span></h2>
            </div>
          </div>
        </div>
        <div id="johnDiv" className="business-details-inner">
          <ul>
            <li>
              <strong>Business Name:</strong>
              <span>{details?.businessName}</span>
            </li>

            <li>
              <strong>Business Title:</strong>
              <span>{details?.businessTitle}</span>
            </li>

            <li>
              <strong>Category:</strong>
              <span>{details?.businessCategory?.name}</span>
            </li>
            <li>
              <strong>Business Created Date:</strong>
              <span>
                {details?.userBusinessCreatedDate &&
                  moment(details?.userBusinessCreatedDate)
                    .subtract(10, "days")
                    .calendar()}
              </span>
            </li>
            <li>
              <strong>You provide services:</strong>
              <span>{details?.userBusinessLocation}</span>
            </li>
            <li>
              <strong>City:</strong>
              <span>{details?.cityVO?.cityName}</span>
            </li>

            <li>
              <strong>Phone Number:</strong>
              <span>{details?.businessContactNumber}</span>
            </li>
            <li>
              <strong>Email:</strong>
              <span>{details?.businessEmail}</span>
            </li>
            <li>
              <strong>Website Url:</strong>
              <span>{details?.businessWebsite}</span>
            </li>
            <li>
              <strong>Facebook:</strong>
              <span>{details?.facebookLink}</span>
            </li>
            <li>
              <strong>Instagram:</strong>
              <span>{details?.instagramLink}</span>
            </li>

            <li>
              <strong>Business Details:</strong>
              <span>
                <a
                  href={details?.businessDirectLink}
                  className="link-offset-3 link-primary"
                  target="_blank"
                >
                  View Details
                </a>
              </span>
            </li>

            <li>
              <strong>Business Street Address:</strong>
              <span>{details?.businessStreetAddress}</span>
            </li>

            <li>
              <strong>Address:</strong>
              <span>
                {details?.businessAddress}{" "}
                <a
                  href={`https://maps.google.com/?q=${details?.latitude},${details?.longitude}`}
                  className="link-offset-3 link-primary"
                  target="_blank"
                >
                  View Map
                </a>
              </span>
            </li>
            <li>
              <strong>Description:</strong>
              <span>{details?.businessDescription}</span>
            </li>
          </ul>
          <div className="d-flex gap-3 my-3">
            <button
              type="button"
              class="btn btn-success"
              data-bs-toggle="modal"
              data-bs-target="#approvePopupBusiness"
              onClick={() => setMessage("")}
            >
              Approve
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-bs-toggle="modal"
              data-bs-target="#rejectPopupBusiness"
              onClick={() => setMessage("")}
            >
              Reject
            </button>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="approvePopupBusiness"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="approvePopupLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="approvePopupLabel">
                Approve Business
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="businessApproveButtom"
              ></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label for="reasonApprove" class="form-label">
                  Reason for approve
                </label>
                <div class="input-group">
                  <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    name="reasonApprove"
                    id="reasonApprove"
                    cols="10"
                    class="form-control"
                    rows="4"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-success"
                onClick={() => sendApprove()}
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="rejectPopupBusiness"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="rejectPopupLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="rejectPopupLabel">
                Reject Business
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                id="businessRejectButtom"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label for="reasonReject" class="form-label">
                  Reason for Reject
                </label>
                <div class="input-group">
                  <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    name="reasonReject"
                    id="reasonReject"
                    cols="10"
                    class="form-control"
                    rows="4"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-danger"
                onClick={() => sendReject()}
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessSelected;
