import categoryListJson from "../important/category.json";


const pageRoutes = {
  home: "/",
  login: "/login",
  dashboard: "/dashboard",
  business: "/business",
  events: "/events",
  addProduct: "/add-product",
  addCampaign: "/add-campaign",
  addEvent: "/add-event",
  contentAdd: "/add-content",
  search: "/search",
  searchBusiness: "/search-business",
  searchProduct: "/search-product",
  searchEvent: "/search-event",
  searchCampaign: "/search-campaign",
  searchEdit: "/edit",
  searchEditUrl: "/edit",
};

const apiUrl = {
  addContent: "/app/admin/addUserContent",
  getDashboardData: "/app/admin/getDashboardData",
  getCategorySubCategory: "/static/getCategorySubCategory",
  loginApi: "/app/admin/loginAdminUser",
  getBusinessListForApproval: "/app/admin/getBusinessListForApproval",
  getEventListForApproval: "/app/admin/getEventListForApproval",
  performAdminAction: "/app/admin/performAdminAction",
  addCampaign: "/app/admin/addCampaign",
  addCampaignSearch: "/app/admin/addCampaign",
  performAdminSearch: "/app/admin/performAdminSearch",

  getBusinessSearchResults: "/app/admin/search/getBusinessSearchResults",
  updateUserBusinessByAdmin: "/app/admin/search/updateUserBusinessByAdmin",

  getEventsSearchResults: "/app/admin/search/getEventsSearchResults",
  updateAnEventByAdmin: "/app/admin/search/updateAnEventByAdmin",

  getProductSearchResults: "/app/admin/search/getProductSearchResults",
  updateProductByAdmin: "/app/admin/search/updateProductByAdmin",


  getCampaignSearchResults: "/app/admin/search/getCampaignSearchResults",
  updateCampaignByAdmin: "/app/admin/search/updateCampaignByAdmin",

  getSearchDetailsPage: "/app/admin/getSearchDetailsPage",
  getUploadFileUrl: "/app/admin/getUploadFileUrl",
  addProduct: "/app/admin/addProduct",
  addEvent: "/app/admin/addAdminEvent",
  saveCardDetails: "/app/admin/saveCardDetails",
};


const apiBaseUrl2 = "https://transpose-gateway.listy.city";
const apiBaseUrl = "https://api.listy.city";

const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
const allowedImage = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
const passwordRegex =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;

const imagetypeError =
  "Please upload file having extensions .jpeg/.jpg/.png/.gif only.";
const passwordValidateError = "Password must be 8-20 characters long and contain at least one number, one lowercase letter, one uppercase letter, and one special character.";

const isPasswordOk = (val) => {
  if (val.match(passwordRegex)) {
    return true;
  } else {
    return false;
  }
};

function isNumeric(input) {
  return /^[0-9]+$/.test(input);
}

const isValidEmail = (val) => {
  if (emailRegex.test(val)) {
    return true;
  } else {
    return false;
  }
};

const trimString = (val) => {
  const outputString = val.replace(/\s+/g, ' ').trim();
  return outputString
}

const dateSendFormate = (val) => {
  if (val) {
    var originalDate = new Date(val);

    var day = originalDate.getDate();
    var month = originalDate.getMonth() + 1;
    var year = originalDate.getFullYear();
    var hours = originalDate.getHours();
    var minutes = originalDate.getMinutes();
    var seconds = originalDate.getSeconds();

    month = (month < 10) ? '0' + month : month;
    day = (day < 10) ? '0' + day : day;
    hours = (hours < 10) ? '0' + hours : hours;
    minutes = (minutes < 10) ? '0' + minutes : minutes;
    seconds = (seconds < 10) ? '0' + seconds : seconds;

    var formattedDateString = day + '-' + month + '-' + year + ' 00:00:00';
    // var formattedDateString = day + '-' + month + '-' + year + ' ' + hours + ':' + minutes + ':' + seconds;
    return formattedDateString;
  } else {
    return ''
  }

}

const dateSendFormate2 = (val) => {
  if (val) {
    var originalDate = new Date(val);

    var day = originalDate.getDate();
    var month = originalDate.getMonth() + 1;
    var year = originalDate.getFullYear();
    var hours = originalDate.getHours();
    var minutes = originalDate.getMinutes();
    var seconds = originalDate.getSeconds();

    month = (month < 10) ? '0' + month : month;
    day = (day < 10) ? '0' + day : day;
    hours = (hours < 10) ? '0' + hours : hours;
    minutes = (minutes < 10) ? '0' + minutes : minutes;
    seconds = (seconds < 10) ? '0' + seconds : seconds;

    var formattedDateString = day + '-' + month + '-' + year + ' ' + hours + ':' + minutes + ':' + seconds;
    return formattedDateString;
  } else {
    return ''
  }

}

function isDateFormatChange(dateString) {
  // Attempt to create a new Date object using the provided date string
  const dateObject = new Date(dateString);

  // Check if the dateObject is a valid date and the date string matches the expected format
  if (!isNaN(dateObject.getTime()) && dateString === dateObject.toISOString().slice(0, 10)) {
    return new Date(dateString)
  } else {
    return ""
  }
}

const isValidUrl = (url) => {
  try {
    const parsedURL = new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

const isImageType = (name) => {
  if (allowedImage.exec(name)) {
    return true;
  } else {
    return false;
  }
};

const isValidIndiaPhone = (val) => {
  var expr = /^(0|91)?[6-9][0-9]{9}$/;
  if (!expr.test(val.replace("+", ''))) {
    return false;
  } else {
    return true
  }

  // const regex = /^\d{10}$/;
  // if (regex.test(val)) {
  //   return true;
  // } else {
  //   return false;
  // }
};

const phoneFormate = (val) => {
  let sanitizedValue = val.replace(/[^0-9+]/g, '');
  if (sanitizedValue.startsWith('+')) {
    sanitizedValue = "+" + sanitizedValue.replaceAll('+', '');
  } else {
    sanitizedValue = sanitizedValue.replaceAll('+', '');
  }
  return sanitizedValue

}

const categoryList = categoryListJson?.categoryList
export {
  dateSendFormate,
  phoneFormate,
  isValidIndiaPhone,
  passwordValidateError,
  isPasswordOk,
  isValidEmail,
  apiBaseUrl, apiBaseUrl2,
  isDateFormatChange,
  pageRoutes,
  isValidUrl,
  isImageType,
  isNumeric,
  imagetypeError,
  apiUrl,
  trimString,
  dateSendFormate2,
  categoryList
};
