import axios from "axios";
import { apiBaseUrl, apiBaseUrl2, apiUrl } from "../important/commanFunction";
import { useEffect, useState } from "react";
import moment from "moment";
import EventSelected from "./EventSelected";
import { ToastContainer, toast } from 'react-toastify';

const Events = () => {
  const [loading, setLoading] = useState(true);
  const [eventsList, setEventsList] = useState([]);
  const [eventSelected, setEventSelected] = useState({});
  const { sessionToken, id } = JSON.parse(localStorage.getItem("userData"));

  const getEventsList = () => {
    setEventSelected({});
    setLoading(true);
    const headers = {
      Accept: "*/*",
      "Content-Type": "application/json",
    };

    if (id && sessionToken) {
      axios
        .post(
          apiBaseUrl + apiUrl.getEventListForApproval,
          { adminUserId:id, sessionToken },
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response?.data?.eventVOS?.length > 0) {
            setEventsList(response?.data?.eventVOS);
          } else {
            setEventsList([]);
          }
        })
        .catch((error) => {
          setEventsList([]);
          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getEventsList();
  }, [sessionToken, id]);

  const removeItem = (id) => {
    setEventsList(eventsList?.filter((res) => res?.userEventId != id));
  };

  return (
    <>
      <div className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Events</h1>
          {!loading && (
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={() => {
                getEventsList();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-clockwise"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                ></path>
                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"></path>
              </svg>
              Refresh
            </button>
          )}
        </div>
        <div className="row business-list">
          <div className="col-md-4 list-group list-group-flush border-bottom scrollarea">
            {loading && (
              <div className="list-loading-main-div">
                <div
                  className="spinner-border text-primary"
                  role="status"
                ></div>
              </div>
            )}
            {eventsList?.length === 0 && !loading && (
              <div className="list-loading-main-div">
                <p className="no_record"> Record not found </p>
              </div>
            )}
            {!loading &&
              eventsList?.length > 0 &&
              eventsList?.map((res, index) => (
                <div
                  key={index}
                  className={`list-group-item list-group-item-action py-3 lh-sm pointer ${
                    res?.userEventId === eventSelected?.userEventId && "active"
                  }`}
                  onClick={() => setEventSelected(res)}
                >
                  <div className="d-flex w-100 align-items-center justify-content-between">
                    <strong className="mb-1">{res?.eventTitle}</strong>
                    <small>
                      {res?.userEventEndDate &&
                        moment(res?.userEventEndDate)
                          .subtract(10, "days")
                          .calendar()}
                    </small>
                  </div>
                  <div className="col-10 mb-1 small">{res?.eventTitle}</div>
                </div>
              ))}
          </div>
          {eventsList.some(
            (obj) => obj.userEventId === eventSelected?.userEventId
          ) &&
            eventSelected?.userEventId && (
              <EventSelected
                details={eventSelected}
                remove={(val) => removeItem(val)}
                showMessage={(res)=>{
                  if(res?.success){
                    res?.msg && toast.success(res?.msg);
                  }else{
                    res?.msg && toast.error(res?.msg);
                  }
                }}
              />
            )}
        </div>
      </div>
      <ToastContainer autoClose={3000} />
    </>
  );
};

export default Events;
