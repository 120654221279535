import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useEffect, useState } from "react";
import axios from "axios";
import { apiBaseUrl, apiBaseUrl2, apiUrl } from "../important/commanFunction";
import { ToastContainer, toast } from 'react-toastify';
import FullPageLoading from './FullPageLoading';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("");
  const [dashboardData, setDashboardData] = useState([]);
  const { sessionToken, id } = JSON.parse(localStorage.getItem("userData"));

  const getDashboardList = () => {
    setLoading(true);
    const headers = {
      Accept: "*/*",
      "Content-Type": "application/json",
    };

    if (id && sessionToken) {
      const filterData = filter ? { reportRange: filter } : {}
      axios
        .post(
          apiBaseUrl + apiUrl.getDashboardData,
          { adminUserId: id, sessionToken, ...filterData },
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response?.data?.dashboardData?.length > 0) {
            setDashboardData(response?.data?.dashboardData);
          } else {
            setDashboardData([]);
          }
        })
        .catch((error) => {
          setDashboardData([]);
          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getDashboardList();
  }, [sessionToken, id , filter]);

  const data = {
    labels: dashboardData?.map((res) => res.dataName || ''), // Labels for Y-axis
    datasets: [
      {
        label: '',
        data: dashboardData?.map((res) => res.dataCount || 0), // Numbers for X-axis
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: 'x', // Set the chart to horizontal bar
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      title: {
        display: false,
        text: 'Bar Chart Example',
      },
    },
    scales: {
      x: {
        beginAtZero: true, // Start X-axis at zero
      },
    },
  };

  return <>
  {loading && <FullPageLoading />}
    <div className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div className="row">
        <div className="col-12 d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Dashboard</h1>
          <select value={filter} onChange={(e) => setFilter(e.target.value)}>
            <option value={""}>
              All
            </option>
            <option value={"TODAY"}>
              Today
            </option>
            <option value={"LAST_WEEK"}>
              Last Week
            </option>
            <option value={"LAST_MONTH"}>
              Last Month
            </option>
          </select>
        </div>
        <div className="col-12" style={{ marginBottom: "50px" }}>
          <Bar data={data} options={options} />
          {/* <img src="images/business-events.png" alt="" className="w-100" /> */}
        </div>
        {dashboardData?.map((res, index) =>
          <div className="col-12 col-md-6 col-lg-3" style={{ marginBottom: "10px" }} key={index}>
            <div className="card text-center gap-3 p-5">
              <span className="display-4">{res.dataCount || 0}</span>
              <h4>{res.dataName || ''}</h4>
              {/* <a href="business.html" className="btn btn-outline-primary">
          Explore
        </a> */}
            </div>
          </div>
        )}


        {/* <div className="col-12 col-md-6 col-lg-3" style={{marginBottom:"10px"}}>
        <div className="card text-center gap-3 p-5">
          <span className="display-4">23+</span>
          <h4>Events</h4>
          <a href="events.html" className="btn btn-outline-primary">
            Explore
          </a>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-3" style={{marginBottom:"10px"}}>
        <div className="card text-center gap-3 p-5">
          <span className="display-4">144+</span>
          <h4>Products</h4>
          <a href="add-product.html" className="btn btn-outline-primary">
            Explore
          </a>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-3" style={{marginBottom:"10px"}}>
        <div className="card text-center gap-3 p-5">
          <span className="display-4">8+</span>
          <h4>Campaigns</h4>
          <a href="add-campaign.html" className="btn btn-outline-primary">
            Explore
          </a>
        </div>
      </div> */}
      </div>
    </div>
    {/* <!-- Popups -->
  <!-- Approve Business Modal Start --> */}
    <div className="modal fade" id="approvePopup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
      aria-labelledby="approvePopupLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="approvePopupLabel">Approve Business</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <label for="reasonApprove" className="form-label">Reason for approve</label>
              <div className="input-group">
                <textarea name="reasonApprove" id="reasonApprove" cols="10" className="form-control"
                  rows="4"></textarea>
              </div>
              {/* <!-- <div className="form-text" id="basic-addon4">Example help text goes outside the input group.</div> --> */}
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" className="btn btn-success">Approve</button>
          </div>
        </div>
      </div>
    </div>
    {/* <!-- Approve Business Modal End --> */}

    {/* <!-- Reject Business Modal Start --> */}
    <div className="modal fade" id="rejectPopup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
      aria-labelledby="rejectPopupLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="rejectPopupLabel">Reject Business</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <label for="reasonReject" className="form-label">Reason for Reject</label>
              <div className="input-group">
                <textarea name="reasonReject" id="reasonReject" cols="10" className="form-control"
                  rows="4"></textarea>
              </div>
              {/* <!-- <div className="form-text" id="basic-addon4">Example help text goes outside the input group.</div> --> */}
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" className="btn btn-danger">Reject</button>
          </div>
        </div>
      </div>
    </div>
    {/* <!-- Reject Business Modal End --> */}
    <ToastContainer autoClose={3000} />

  </>
};

export default Dashboard;
