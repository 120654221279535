const ConformPopUp = ({onClose , submit , title ,textMsg }) => {
    return (
        <div className="loading_main_div">
            <div className="add_campain" >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" >{title}</h1>
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => onClose()}></button>
                        </div>
                        <div className="modal-body">
                            <div className="needs-validation" novalidate="">
                                <h5>{textMsg}</h5>
                            </div>
                            <div className="modal-footer mt-3">
                                <button type="button" className="btn btn-secondary" onClick={() => onClose()} style={{ marginRight: "20px" }}>Close</button>
                                <button type="button" className="btn btn-primary" onClick={() => submit()}>Yes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConformPopUp;