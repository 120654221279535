import axios from "axios";
import { useEffect, useState } from "react";
import { apiBaseUrl, apiBaseUrl2, apiUrl } from "../important/commanFunction";
import moment from "moment/moment";
import BusinessSelected from "./BusinessSelected";
import { ToastContainer, toast } from 'react-toastify';

const Business = () => {
  const [loading, setLoading] = useState(true);
  const [businessList, setBusinessList] = useState([]);
  const [businessSelected, setBusinessSelected] = useState({});
  const { sessionToken, id } = JSON.parse(localStorage.getItem("userData"));

  const getBusinessList = () => {
    setBusinessSelected({});
    setLoading(true);
    const headers = {
      Accept: "*/*",
      "Content-Type": "application/json",
    };

    if (id && sessionToken) {
      axios
        .post(
          apiBaseUrl + apiUrl.getBusinessListForApproval,
          { adminUserId:id, sessionToken },
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response?.data?.businessVOS?.length > 0) {
            setBusinessList(response?.data?.businessVOS);
          } else {
            setBusinessList([]);
          }
        })
        .catch((error) => {
          setBusinessList([]);
          // Handle any errors
          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getBusinessList();
  }, [sessionToken, id]);
  
  const removeItem = (id) => {
    setBusinessList(businessList?.filter((res) => res?.userBusinessId != id));
  };

  return (
    <>
      <div className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Business List</h1>
          {!loading && (
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={() => {
                getBusinessList();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-clockwise"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                ></path>
                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"></path>
              </svg>
              Refresh
            </button>
          )}
        </div>
        <div className="row business-list">
          <div className="col-md-4 list-group list-group-flush border-bottom scrollarea">
            {loading && (
              <div className="list-loading-main-div">
                <div
                  className="spinner-border text-primary"
                  role="status"
                ></div>
              </div>
            )}
            {businessList?.length === 0 && !loading && (
              <div className="list-loading-main-div">
                <p className="no_record"> Record not found </p>
              </div>
            )}
            {!loading &&
              businessList?.length > 0 &&
              businessList?.map((res, index) => (
                <div
                  key={index}
                  className={`list-group-item list-group-item-action py-3 lh-sm pointer ${
                    res?.userBusinessId === businessSelected?.userBusinessId &&
                    "active"
                  }`}
                  onClick={() => setBusinessSelected(res)}
                >
                  <div className="d-flex w-100 align-items-center justify-content-between">
                    <strong className="mb-1">{res?.businessTitle}</strong>
                    <small>
                      {res?.userBusinessCreatedDate &&
                        moment(res?.userBusinessCreatedDate)
                          .subtract(10, "days")
                          .calendar()}
                    </small>
                  </div>
                  <div className="col-10 mb-1 small">{res?.businessName}</div>
                </div>
              ))}
          </div>
          {businessList.some(
            (obj) => obj.userBusinessId === businessSelected?.userBusinessId
          ) && businessSelected?.userBusinessId && (
            <BusinessSelected details={businessSelected} 
             remove={(val) => removeItem(val)}
            showMessage={(res)=>{
              if(res?.success){
                res?.msg && toast.success(res?.msg);
              }else{
                res?.msg && toast.error(res?.msg);
              }
            }}/>
          )}
        </div>
      </div>
      <ToastContainer autoClose={3000} />

    
     
    </>
  );
};

export default Business;
