import { Outlet } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import ProtectedRoute from "./ProtectedRoute";

const Layout = () => {
  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default ProtectedRoute(Layout);
