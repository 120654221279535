import 'react-toastify/dist/ReactToastify.css';
import "./style.css";
import "./App.css";
import "react-bootstrap-typeahead/css/Typeahead.css";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "./component/Layout";
import Dashboard from "./component/Dashboard";
import Login from "./component/Login";
import SearchBusiness from "./component/SearchBusiness";
import SearchEvent from "./component/SearchEvent";
import SearchCampaign from "./component/SearchCampaign";
import SearchProduct from "./component/SearchProduct";
import Business from "./component/Business";
import Events from "./component/Events";
import AddCampaign from "./component/AddCampaign";
import AddProduct from "./component/AddProduct";
import { pageRoutes } from "./important/commanFunction";
import AddEvent from './component/AddEvent';
import ContentAdd from './component/ContentAdd';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={pageRoutes.login} element={<Login />} />
        <Route path={"/"} element={<Login />} />
        <Route path={"/"} element={<Layout />}>
          <Route path={pageRoutes.dashboard} element={<Dashboard />} />
          <Route path={pageRoutes.searchBusiness} element={<SearchBusiness />} />
          <Route path={pageRoutes.searchEvent} element={<SearchEvent />} />
          <Route path={pageRoutes.searchCampaign} element={<SearchCampaign />} />
          <Route path={pageRoutes.searchProduct} element={<SearchProduct />} />
          <Route path={pageRoutes.business} element={<Business />} />
          <Route path={pageRoutes.events} element={<Events />} />
          <Route path={pageRoutes.addEvent} element={<AddEvent />} />
          <Route path={pageRoutes.addProduct} element={<AddProduct />} />
          <Route path={pageRoutes.contentAdd} element={<ContentAdd />} />
          <Route path={pageRoutes.addCampaign} element={<AddCampaign />} />
        </Route>
        <Route path={"*"} element={<Navigate to={pageRoutes.login} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
