import { useState } from "react";
import FullPageLoading from "./FullPageLoading";
import { ToastContainer, toast } from "react-toastify";
import { apiBaseUrl, apiBaseUrl2, apiUrl,  dateSendFormate2, imagetypeError, isImageType, pageRoutes } from "../important/commanFunction";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import messageShow from "../important/message.json"

const cardActionList = [
  {
    value: "ADD_EVENT",
    name: "Add Event"
  },
  {
    value: "VIEW_PRODUCT",
    name: "View product"
  },
  {
    value: "VIEW_HERO",
    name: "View hero"
  },
  {
    value: "VIEW_EVENT",
    name: "View Event"
  },
  {
    value: "VIEW_BUSINESS",
    name: "View Business"
  },
  {
    value: "REGISTER_BUSINESS",
    name: "Register Business"
  },
]
const SearchAddCampainPopUP = ({ onClose, details }) => {
  const [formInput, setFormInput] = useState({});
  const [loading, setLoading] = useState(false);
  const [formInputError, setFormInputError] = useState({});
  const { sessionToken, id } = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const logOut = () => {
    localStorage.removeItem("userData");
    navigate(pageRoutes.login);
  };

  const fileChange = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.files[0],
    });
  };

  const inputChange = (e) => {
    if (e.target.name === "startDate") {
      setFormInput({
        ...formInput,
        [e.target.name]: e.target.value,
        // endDate: "",
      });
    } else if (e.target.name === "cardId") {
      const numericValue = e.target.value.replace(/[^0-9]/g, "");
      setFormInput({
        ...formInput,
        [e.target.name]: numericValue,
      });
    } else {
      setFormInput({
        ...formInput,
        [e.target.name]: e.target.value,
      });
    }
  };

  const validateForm = (data) => {
    const errors = {};

    const startDateObject = new Date(data.startDate);
    const endDateObject = new Date(data.endDate);

    if (!data.endDate) {
      errors.endDate = messageShow.error.end_date_req;
    } else if (startDateObject > endDateObject) {
      errors.endDate = messageShow.error.end_date_less;
    }

    if (!data.startDate) {
      errors.startDate = messageShow.error.start_date_req;
    }

    // if (!data.cardAction) {
    //   errors.cardAction = "Card Action  is required";
    // }

    if (!data.description) {
      errors.description = messageShow.error.description_req;
    }


    // if (!data.productImage) {
    //   errors.productImage = messageShow.error.image_req;
    // } else if (!isImageType(data?.productImage?.name)) {
    //   errors.productImage = imagetypeError;
    // }

    if (!data.title) {
      errors.title = messageShow.error.title_req;
    }

    return errors;
  };

  const headers = {
    Accept: "*/*",
    "Content-Type": "application/json",
  };

  const addCampain = () => {
    axios
      .post(
        apiBaseUrl + apiUrl.addCampaignSearch,
        {
          ...details,
          startDate: dateSendFormate2(formInput?.startDate),
          endDate: dateSendFormate2(formInput?.endDate),
          title: formInput?.title,
          description: formInput?.description,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response?.data?.responseType === "SUCCESS") {
          onClose()
          toast.success("Successfully Campaign Add");
          setFormInput({})
        } else {
          onClose()
          toast.error(
            messageShow.error.something_wrong
          );
        }
      })
      .catch((error) => {
        onClose()
        toast.error(messageShow.error.something_wrong);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const formSubmit = () => {
    const validationErrors = validateForm(formInput);
    if (Object.keys(validationErrors).length === 0) {

      if (id && sessionToken) {
        setLoading(true);
        addCampain()
      } else {
        logOut();
      }
    }
    setFormInputError(validationErrors);
  }
  return (
    <>
      <div className="loading_main_div">
        <div className="add_campain" >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" >Add Campaign</h1>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => onClose()}></button>
              </div>
              <div className="modal-body">
                <div className="needs-validation" novalidate="">
                  <div className="row g-3">
                    {/* <div className="col-12">
                      <label for="upload_image" className="form-label">
                        Upload Campaign Image
                      </label>
                      <div className="input-group has-validation">
                        <input
                          accept="image/*"
                          name="productImage"
                          type="file"
                          className={`form-control ${formInputError?.productImage ? "is-invalid" : ""
                            }`}
                          id="upload_image"
                          required
                          onChange={fileChange}
                        />
                        {formInputError?.productImage && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {formInputError?.productImage}
                          </div>
                        )}
                      </div>
                    </div> */}
                    <div className="col-12">
                      <label for="title" className="form-label">
                        Title
                      </label>
                      <input
                        type="text"
                        className={`form-control ${formInputError?.title ? "is-invalid" : ""
                          }`}
                        id="title"
                        placeholder=""
                        value={formInput?.title || ""}
                        name="title"
                        onChange={inputChange}
                      />
                      {formInputError?.title && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {formInputError?.title}
                        </div>
                      )}
                    </div>
                    <div className="col-12">
                      <label for="desc" className="form-label">
                        Description
                      </label>
                      <textarea
                        value={formInput?.description || ""}
                        name="description"
                        onChange={inputChange}
                        id="desc"
                        cols="30"
                        rows="5"
                        className={`form-control ${formInputError?.description ? "is-invalid" : ""
                          }`}
                        required
                      ></textarea>
                      {formInputError?.description && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {formInputError?.description}
                        </div>
                      )}
                    </div>
                    {/* <div className="col-12 col-md-6">
                      <label for="cardActionList" className="form-label">
                        Card Action
                      </label>

                      <select
                        className={`form-select ${formInputError?.cardAction ? "is-invalid" : ""
                          }`}
                        id="cardActionList"
                        required
                        value={formInput?.cardAction || ""}
                        name="cardAction"
                        onChange={inputChange}
                      >
                        <option value="" disabled selected>
                          Choose Card Action...
                        </option>
                        {cardActionList?.map((res, index) => (
                          <option key={index} value={res?.value}>
                            {res?.name}
                          </option>
                        ))}
                      </select>
                      {formInputError?.cardAction && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {formInputError?.cardAction}
                        </div>
                      )}
                    </div>{" "} */}
                    <div className="col-12 col-sm-6">
                      <label for="start_date" className="form-label">
                        Start Date
                      </label>
                      <input
                        value={formInput?.startDate || ""}
                        name="startDate"
                        onChange={inputChange}
                        type="datetime-local"
                        className={`form-control ${formInputError?.startDate ? "is-invalid" : ""
                          }`}
                        id="start_date"
                        placeholder=""
                        required=""
                      />
                      {formInputError?.startDate && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {formInputError?.startDate}
                        </div>
                      )}
                    </div>
                    <div className="col-12 col-sm-6">
                      <label for="end_date" className="form-label">
                        End Date
                      </label>
                      <input
                        type="datetime-local"
                        className={`form-control ${formInputError?.endDate ? "is-invalid" : ""
                          }`}
                        id="end_date"
                        placeholder=""
                        min={
                          formInput.startDate &&
                          new Date(
                            new Date(formInput.startDate).getTime() + 86400000
                          )
                            .toISOString()
                            .split("T")[0]
                        }
                        value={formInput?.endDate || ""}
                        name="endDate"
                        onChange={inputChange}
                        required=""
                      />
                      {formInputError?.endDate && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {formInputError?.endDate}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="modal-footer mt-3">
                  <button type="button" className="btn btn-secondary" onClick={() => onClose()} style={{ marginRight: "20px" }}>Close</button>
                  <button type="button" className="btn btn-primary" onClick={() => formSubmit()}>Add Campaign</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <FullPageLoading />}
      <ToastContainer autoClose={3000} />

    </>
  );
};

export default SearchAddCampainPopUP;
