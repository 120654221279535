import axios from "axios";
import moment from "moment";
import { apiBaseUrl, apiBaseUrl2, apiUrl, pageRoutes } from "../important/commanFunction";
import { useState } from "react";
import FullPageLoading from "./FullPageLoading";
import { useNavigate } from "react-router-dom";
import messageShow from "../important/message.json"

const EventSelected = ({ details, remove, showMessage }) => {
  const { sessionToken, id } = JSON.parse(localStorage.getItem("userData"));
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const logOut = () => {
    localStorage.removeItem("userData");
    navigate(pageRoutes.login);
  }

  const sendApprove = () => {
    const button = document.getElementById("eventApproveButtom");

    setLoading(true);
    const headers = {
      Accept: "*/*",
      "Content-Type": "application/json",
    };

    if (id && sessionToken) {
      axios
        .post(
          apiBaseUrl + apiUrl.performAdminAction,
          {
            adminActionType: "APPROVE",
            cardId: details?.userEventId,
            cardType: "EVENT",
            comments: "string",
            adminUserId: id,
            sessionToken,
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log(response);
          if (response?.data?.responseType === "SUCCESS") {
            if (button) {
              button.click();
            }
            showMessage({ success: true, msg: "Successfully Event Approve !" });
            remove(details?.userEventId);
          }
        })
        .catch((error) => {
          showMessage({
            success: false,
            msg: messageShow.error.something_wrong,
          });
          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false);
          setMessage("");
        });
    } else {
      logOut()
    }
  };

  const sendReject = () => {
    const button = document.getElementById("eventRejectButtom");
    setLoading(true);
    const headers = {
      Accept: "*/*",
      "Content-Type": "application/json",
    };

    if (id && sessionToken) {
      axios
        .post(
          apiBaseUrl + apiUrl.performAdminAction,
          {
            adminActionType: "DISAPPROVE",
            cardId: details?.userEventId,
            cardType: "EVENT",
            comments: "string",
            adminUserId: id,
            sessionToken,
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log(response);
          if (response?.data?.responseType === "SUCCESS") {
            if (button) {
              button.click();
            }
            remove(details?.userEventId);
            showMessage({ success: true, msg: "Successfully Event Reject !" });
          }
        })
        .catch((error) => {
          showMessage({
            success: false,
            msg: messageShow.error.something_wrong,
          });
          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false);
          setMessage("");
        });
    } else {
      logOut()
    }
  };

  return (
    <>
      {loading && <FullPageLoading />}
      <div className="col-md-8 business-details">
        <div id="johnDiv" className="business-details-inner">
          <div className="image-container">
            {details?.eventImageLocation ? (
              <img
                src={details?.eventImageLocation}
                className="w-100"
                style={{ maxWidth: "400px" }}
                alt=""
              />
            ) : <p>Image Not Found</p>}
            <div>
              <h3>Status : {details?.userEventStatus}</h3>
              <h3>Id : {details?.userEventId}</h3>
            </div>
          </div>

          <ul>
            <li>
              <strong>Event Name:</strong>
              <span>{details?.eventTitle}</span>
            </li>
            <li>
              <strong>Event Charges:</strong>
              <span>{details?.eventCharges}</span>
            </li>
            <li>
              <strong>Event Type:</strong>
              <span>{details?.userEventType}</span>
            </li>
            <li>
              <strong>Event Direct Link:</strong>
              <span>
                {details?.eventDirectLink ? <a href={details?.eventDirectLink} target="_blank">
                  Join Event
                </a> : <p>NaN</p>}
              </span>
            </li>
            <li>
              <strong>Event Link:</strong>
              <span>
                {details?.eventLink ? <a href={details?.eventLink} target="_blank">
                  {details?.eventLink}
                </a> : <p>NaN</p>}
              </span>
            </li>
            <li>
              <strong>Facebook Link:</strong>
              <span>
                {details?.facebookLink}
              </span>
            </li>
            <li>
              <strong>Instagram Link:</strong>
              <span>
                {details?.instagramLink}
              </span>
            </li>
            <li>
              <strong>Start Date:</strong>
              <span>
                {details?.userEventStartDate &&
                  moment(details?.userEventStartDate)
                    .subtract(10, "days")
                    .calendar()}
              </span>
            </li>
            <li>
              <strong>Event Created Date:</strong>
              <span>
                {details?.userEventCreatedDate &&
                  moment(details?.userEventCreatedDate)
                    .subtract(10, "days")
                    .calendar()}
              </span>
            </li>
            <li>
              <strong>End Date:</strong>
              <span>
                {details?.userEventEndDate &&
                  moment(details?.userEventEndDate)
                    .subtract(10, "days")
                    .calendar()}
              </span>
            </li>


            <li>
              <strong>Category:</strong>
              <span>{details?.eventCategory?.name}</span>
            </li>

            <li>
              <strong>City:</strong>
              <span>{details?.cityVO?.cityName}</span>
            </li>
            <li>
              <strong>Event Street Address:</strong>
              <span>{details?.eventStreetAddress}</span>
            </li>
            <li>
              <strong>Address:</strong>
              <span>
                {details?.eventAddress}{" "}
                <a
                  href={`https://maps.google.com/?q=${details?.latitude},${details?.longitude}`}
                  className="link-offset-3 link-primary"
                  target="_blank"
                >
                  View Map
                </a>
              </span>
            </li>
            <li>
              <strong>Description:</strong>
              <span>{details?.eventDescription}</span>
            </li>
          </ul>
          <div className="d-flex gap-3 my-3">
            <button
              type="button"
              className="btn btn-success"
              data-bs-toggle="modal"
              data-bs-target="#approvePopup"
              onClick={() => setMessage("")}
            >
              Approve
            </button>
            <button
              type="button"
              className="btn btn-danger"
              data-bs-toggle="modal"
              data-bs-target="#rejectPopup"
              onClick={() => setMessage("")}
            >
              Reject
            </button>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="approvePopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="approvePopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="approvePopupLabel">
                Approve Event
              </h1>
              <button
                id="eventApproveButtom"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label for="reasonApprove" className="form-label">
                  Reason for approve
                </label>
                <div className="input-group">
                  <textarea
                    name="reasonApprove"
                    id="reasonApprove"
                    cols="10"
                    className="form-control"
                    rows="4"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={sendApprove}
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="rejectPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="rejectPopupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="rejectPopupLabel">
                Reject Event
              </h1>
              <button
                id="eventRejectButtom"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label for="reasonReject" className="form-label">
                  Reason for Reject
                </label>
                <div className="input-group">
                  <textarea
                    name="reasonReject"
                    id="reasonReject"
                    cols="10"
                    className="form-control"
                    rows="4"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => sendReject()}
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventSelected;
