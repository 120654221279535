import { Link, useNavigate } from "react-router-dom";
import { pageRoutes } from "../important/commanFunction";

const Header = () => {
  const navigate = useNavigate();
  
  const logOut = () => {
    localStorage.removeItem("userData");
    navigate(pageRoutes.login); 
  };

  return (
    <header
      className="navbar sticky-top bg-white flex-md-nowrap px-2 px-md-5
        shadow-sm"
      data-bs-theme="dark"
    >
      <Link
        to={pageRoutes.dashboard}
        className="navbar-brand col-md-3 col-lg-2 me-0 fs-6 text-white"
      >
        <img src="images/logo.png" alt="" />
      </Link>
      <div className="d-flex ">
        <span className="btn btn-primary pointer" onClick={logOut}>
          Logout
        </span>
        <button
          className="d-block d-md-none nav-link px-3"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <img src="images/menu.svg" alt="" width="24" />
        </button>
      </div>

      <div id="navbarSearch" className="navbar-search w-100 collapse">
        <input
          className="form-control w-100 rounded-0 border-0"
          type="text"
          placeholder="Search"
          aria-label="Search"
        />
      </div>
    </header>
  );
};

export default Header;
