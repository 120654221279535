import { useState } from "react";
import WithOutLoginPage from "./WithOutLoginpage";
import {
  apiBaseUrl, apiBaseUrl2,
  apiUrl,
  isPasswordOk,
  isValidEmail,
  pageRoutes,
  passwordValidateError,
} from "../important/commanFunction";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import messageShow from "../important/message.json"

const Login = () => {
  const [formInput, setFormInput] = useState({});
  const [formInputError, setFormInputError] = useState({});
  const navigate = useNavigate();

  const pageChange = (path) => {
    navigate(path);
  };

  const inputChange = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.password) {
      errors.password = "Password is required";
    } else if (!isPasswordOk(data.password)) {
      errors.password = passwordValidateError
    }

    if (!data.emailAddress) {
      errors.emailAddress = "Email address is required";
    } else if (!isValidEmail(data.emailAddress)) {
      errors.emailAddress = messageShow.error.valid_email;
    }

    return errors;
  };

  const formSubmit = () => {
    const validationErrors = validateForm(formInput);
    if (Object.keys(validationErrors).length === 0) {
      const headers = {
        accept: "*/*",
      };

      // Make the POST request using Axios
      axios
        .post(apiBaseUrl + apiUrl.loginApi, null, {
          params: formInput,
          headers: headers,
        })
        .then((response) => {
          // Handle the response data
          if (
            response?.data?.responseType === "SUCCESS" &&
            response?.data?.adminUserLoginVO?.sessionToken
          ) {
            localStorage.setItem(
              "userData",
              JSON.stringify({
                ...response?.data?.adminUserLoginVO,
                token: response?.data?.adminUserLoginVO?.sessionToken,
              })
            );
            pageChange(pageRoutes.dashboard);
          } else if (response?.data?.responseType === "USER_ERROR") {
            toast.error(
              response?.data?.responseMessage
            )
          } else {
            toast.error(
              messageShow.error.something_wrong
            );
          }
        })
        .catch((error) => {
          setFormInput({
            password: "",
            emailAddress: ''
          })
          // Handle any errors
          // console.error("Error:", error);
        });
    }
    setFormInputError(validationErrors);
  };

  return (
    <div className="signin-container bg-body-tertiary">
      <div className="form-signin needs-validation m-auto bg-white p-5">
        <img className="mb-4" src="images/logo.png" alt="Listy City" />
        <h1 className="h3 mb-3 fw-normal">Please sign in</h1>

        <div className="form-floating">
          <input
            onChange={inputChange}
            value={formInput?.emailAddress || ""}
            name="emailAddress"
            type="text"
            className={`form-control ${formInputError?.emailAddress ? "is-invalid" : ""
              }`}
            id="floatingInput"
            placeholder="name@example.com"
          />
          <label for="floatingInput">Email address</label>
          {formInputError?.emailAddress && (
            <div className="invalid-feedback" style={{ display: "block" }}>
              {formInputError?.emailAddress}
            </div>
          )}
        </div>
        <div className="form-floating mt-3">
          <input
            onChange={inputChange}
            value={formInput?.password || ""}
            name="password"
            type="password"
            className={`form-control ${formInputError?.password ? "is-invalid" : ""
              }`}
            id="floatingPassword"
            placeholder="Password"
          />
          <label for="floatingPassword">Password</label>
          {formInputError?.password && (
            <div className="invalid-feedback" style={{ display: "block" }}>
              {formInputError?.password}
            </div>
          )}
        </div>
        <button
          className="btn btn-primary w-100 py-3 my-4 fw-bold"
          onClick={formSubmit}
        >
          Sign in
        </button>
      </div>
      <ToastContainer autoClose={3000} />

    </div>
  );
};

export default WithOutLoginPage(Login);
