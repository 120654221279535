import { useState, useEffect } from "react";
import {
    apiBaseUrl, apiBaseUrl2,
    apiUrl,
    categoryList,
    dateSendFormate2,
    imagetypeError,
    isImageType,
    isValidEmail,
    isValidIndiaPhone,
    isValidUrl,
    pageRoutes,
    phoneFormate,
} from "../important/commanFunction";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import messageShow from "../important/message.json"

// import category from "../important/category.json";
import FullPageLoading from "./FullPageLoading";
import { ToastContainer, toast } from "react-toastify";
import ConformPopUp from "./ConformPopUP";

const AddProduct = () => {
    const [formInput, setFormInput] = useState({});
    const [formInputError, setFormInputError] = useState({});
    const navigate = useNavigate();
    const { sessionToken, id } = JSON.parse(localStorage.getItem("userData"));
    const [loading, setLoading] = useState(false);
    const [category, setCategory] = useState([]);

    useEffect(() => {
        setCategory(categoryList)
        // axios
        //   .get(
        //     apiBaseUrl2 + apiUrl.getCategorySubCategory
        //   )
        //   .then((response) => {
        //     setCategory(response.data)
        //   })
        //   .catch((error) => {
        //     console.error("Error:", error);
        //   })
        //   .finally(() => {
        //     // setLoading(false);
        //   });
    }, [categoryList])

    const logOut = () => {
        localStorage.removeItem("userData");
        navigate(pageRoutes.login);
    };

    const inputChange = (e) => {
        if (e.target.name === "contactNumber") {
            // const numericValue = e.target.value.replace(/[^0-9]/g, "");
            setFormInput({
                ...formInput,
                [e.target.name]: phoneFormate(e.target.value),
            });
        } else if (e.target.name === "startDate") {
            setFormInput({
                ...formInput,
                [e.target.name]: e.target.value,
                // endDate: "",
            });
        } else {
            setFormInput({
                ...formInput,
                [e.target.name]: e.target.value,
            });
        }
    };

    const fileChange = (e) => {
        setFormInput({
            ...formInput,
            [e.target.name]: e.target.files[0],
        });
    };

    const validateForm = (data) => {
        const errors = {};

        if (!data.contactNumber) {
            errors.contactNumber = messageShow.error.phone_req;
        } else if (!isValidIndiaPhone(data.contactNumber)) {
            errors.contactNumber = messageShow.error.valid_phone;
        }

        if (!data.title) {
            errors.title = messageShow.error.title_req;
        }
        if (!data.name) {
            errors.name = messageShow.error.name_req;
        }

        if (!data.address) {
            errors.address = messageShow.error.address_req;
        }

        if (!data.description) {
            errors.description = messageShow.error.description_req;
        }

        const startDateObject = new Date(data.startDate);
        const endDateObject = new Date(data.endDate);

        if (!data.endDate) {
            errors.endDate = messageShow.error.end_date_req;
        } else if (startDateObject > endDateObject) {
            errors.endDate = messageShow.error.end_date_less;
        }

        if (!data.startDate) {
            errors.startDate = messageShow.error.start_date_req;
        }

        if (!data.productCategory) {
            errors.productCategory = messageShow.error.category_req;
        }

        if (!data.website) {
            // errors.website = "Website url is required";
        } else if (data.website && !isValidUrl(data?.website)) {
            errors.website = messageShow.error.valid_url;
        }

        if (!data.facebook) {
            // errors.facebook = "Facebook url is required";
        } else if (data.facebook && !isValidUrl(data?.facebook)) {
            errors.facebook = messageShow.error.valid_url;
        }

        if (!data.instagram) {
            // errors.instagram = "Instagram url is required";
        } else if (data.instagram && !isValidUrl(data?.instagram)) {
            errors.instagram = messageShow.error.valid_url;
        }

        if (!data.imageUrl) {
            // errors.imageUrl = "imageUrl url is required";
        } else if (data.imageUrl && !isValidUrl(data?.imageUrl)) {
            errors.imageUrl = messageShow.error.valid_url;
        }

        if (!data?.imageUrl) {
            if (!data.productImage) {
                errors.productImage = messageShow.error.image_req;
            } else if (!isImageType(data?.productImage?.name)) {
                errors.productImage = imagetypeError;
            }
        }

        if (!data.email) {
            // errors.email = "Email is required";
        } else if (!isValidEmail(data.email)) {
            errors.email = messageShow.error.valid_email;
        }

        return errors;
    };
    const [updatePopUp, setUpdatePopUp] = useState(false)
    const headers = {
        Accept: "*/*",
        "Content-Type": "application/json",
    };

    const createNewProject = (img) => {
        axios
            .post(
                apiBaseUrl + apiUrl.addProduct,
                {
                    address: formInput.address,
                    contactNumber: formInput?.contactNumber ? formInput?.contactNumber.slice(-10) : '',
                    description: formInput.description,
                    email: formInput.email,
                    endDate: dateSendFormate2(formInput.endDate),
                    instagram: formInput.instagram,
                    facebook: formInput.facebook,
                    adminUserId: id,
                    name: formInput.name,
                    productCategory: formInput.productCategory,
                    productImage: img,
                    sessionToken: sessionToken,
                    startDate: dateSendFormate2(formInput.startDate),
                    title: formInput.title,
                    website: formInput.website,

                },
                {
                    headers: headers,
                }
            )
            .then((response) => {
                if (response?.data?.responseType === "SUCCESS") {
                    toast.success("Successfully Product Add");
                    setFormInput({})
                } else {
                    toast.error(
                        messageShow.error.something_wrong
                    );
                }
            })
            .catch((error) => {
                toast.error(messageShow.error.something_wrong);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const fileUploadRequest = () => {
        axios
            .post(
                apiBaseUrl + apiUrl.getUploadFileUrl,
                {
                    cardType: formInput?.cardType,
                    cardId: formInput?.cardId,
                    adminUserId: id,
                    sessionToken: sessionToken,
                    fileName: formInput?.productImage?.name,
                }, {
                headers: headers,
            }
            )
            .then((response) => {
                if (response?.status === 200) {
                    const contentType = response?.data?.mimeType
                    const uploadUrl = response?.data?.url;
                    const imageUrl = response?.data?.fileAccessUrl;

                    let data = formInput?.productImage;

                    let config = {
                        method: 'put',
                        maxBodyLength: Infinity,
                        url: uploadUrl,
                        headers: {
                            'Content-Type': contentType
                        },
                        data: data
                    };

                    axios.request(config)
                        .then((response) => {
                            if (response.status === 200) {
                                createNewProject(imageUrl)
                            } else {
                                setLoading(false);
                                toast.error(messageShow.error.something_wrong);
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            toast.error(messageShow.error.something_wrong);
                        });


                }
                else {
                    setLoading(false);
                    toast.error(
                        messageShow.error.something_wrong
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error(messageShow.error.something_wrong);
            })
    }
    const submit = () => {
        if (id && sessionToken) {
            setLoading(true);

            if (formInput?.productImage?.name && !formInput?.imageUrl) {
                fileUploadRequest()
            } else {
                createNewProject(formInput?.imageUrl || '')
            }

        } else {
            logOut();
        }
    }

    const formSubmit = () => {

        const validationErrors = validateForm(formInput);
        if (Object.keys(validationErrors).length === 0) {
            setUpdatePopUp(true)
        }
        setFormInputError(validationErrors);

    };

    return (
        <>
            <div
                className={`col-md-9 ms-sm-auto col-lg-10 px-md-4 ${loading && "disabled"
                    }`}
            >
                <div className="row">
                    <div className="col-12 col-md-8 col-lg-6 mx-auto mb-5">
                        <div className="col-12 py-3">
                            <h1>Add Product</h1>
                        </div>
                        <div className="needs-validation">
                            <div className="row g-3">
                                <div className="col-12">
                                    <label for="imageUrl" className="form-label">
                                        Image Url
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${formInputError?.imageUrl ? "is-invalid" : ""
                                            }`}
                                        id="imageUrl"
                                        placeholder=""
                                        value={formInput?.imageUrl || ""}
                                        name="imageUrl"
                                        onChange={inputChange}
                                    />
                                    {formInputError?.imageUrl && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {formInputError?.imageUrl}
                                        </div>
                                    )}
                                </div>
                                {!formInput?.imageUrl &&
                                    <div className="col-12">
                                        <label for="upload_image" className="form-label">
                                            Upload Product Image
                                        </label>
                                        <div className="input-group has-validation">
                                            <input
                                                accept="image/*"
                                                name="productImage"
                                                type="file"
                                                className={`form-control ${formInputError?.productImage ? "is-invalid" : ""
                                                    }`}
                                                id="upload_image"
                                                required
                                                onChange={fileChange}
                                            />
                                            {formInputError?.productImage && (
                                                <div
                                                    className="invalid-feedback"
                                                    style={{ display: "block" }}
                                                >
                                                    {formInputError?.productImage}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                }
                                <div className="col-12">
                                    <label for="name" className="form-label">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${formInputError?.name ? "is-invalid" : ""
                                            }`}
                                        id="name"
                                        placeholder=""
                                        value={formInput?.name || ""}
                                        name="name"
                                        onChange={inputChange}
                                    />
                                    {formInputError?.name && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {formInputError?.name}
                                        </div>
                                    )}
                                </div>
                                <div className="col-12">
                                    <label for="title" className="form-label">
                                        Title
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${formInputError?.title ? "is-invalid" : ""
                                            }`}
                                        id="title"
                                        placeholder=""
                                        value={formInput?.title || ""}
                                        name="title"
                                        onChange={inputChange}
                                    />
                                    {formInputError?.title && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {formInputError?.title}
                                        </div>
                                    )}
                                </div>
                                <div className="col-12">
                                    <label for="contactNumber" className="form-label">
                                        Contact Number
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${formInputError?.contactNumber ? "is-invalid" : ""
                                            }`}
                                        id="contactNumber"
                                        placeholder=""
                                        value={formInput?.contactNumber || ""}
                                        name="contactNumber"
                                        onChange={inputChange}
                                    />
                                    {formInputError?.contactNumber && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {formInputError?.contactNumber}
                                        </div>
                                    )}
                                </div>
                                <div className="col-12">
                                    <label for="desc" className="form-label">
                                        Description
                                    </label>
                                    <textarea
                                        value={formInput?.description || ""}
                                        name="description"
                                        onChange={inputChange}
                                        id="desc"
                                        cols="30"
                                        rows="5"
                                        className={`form-control ${formInputError?.description ? "is-invalid" : ""
                                            }`}
                                        required
                                    ></textarea>
                                    {formInputError?.description && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {formInputError?.description}
                                        </div>
                                    )}
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label for="start_date" className="form-label">
                                        Start Date
                                    </label>
                                    <input
                                        value={formInput?.startDate || ""}
                                        name="startDate"
                                        onChange={inputChange}
                                        type="datetime-local"
                                        className={`form-control ${formInputError?.startDate ? "is-invalid" : ""
                                            }`}
                                        id="start_date"
                                        placeholder=""
                                        required=""
                                    />
                                    {formInputError?.startDate && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {formInputError?.startDate}
                                        </div>
                                    )}
                                </div>

                                <div className="col-12 col-sm-6">
                                    <label for="end_date" className="form-label">
                                        End Date
                                    </label>
                                    <input
                                        type="datetime-local"
                                        min={
                                            formInput.startDate &&
                                            new Date(
                                                new Date(formInput.startDate).getTime() + 86400000
                                            )
                                                .toISOString()
                                                .split("T")[0]
                                        }
                                        className={`form-control ${formInputError?.endDate ? "is-invalid" : ""
                                            }`}
                                        id="end_date"
                                        placeholder=""
                                        value={formInput?.endDate || ""}
                                        name="endDate"
                                        onChange={inputChange}
                                        required=""
                                    />
                                    {formInputError?.endDate && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {formInputError?.endDate}
                                        </div>
                                    )}
                                </div>

                                <div className="col-12 col-md-6">
                                    <label for="category" className="form-label">
                                        Category
                                    </label>

                                    <select
                                        className={`form-select ${formInputError?.productCategory ? "is-invalid" : ""
                                            }`}
                                        id="category"
                                        required
                                        value={formInput?.productCategory || ""}
                                        name="productCategory"
                                        onChange={inputChange}
                                    >
                                        <option value="" disabled selected>
                                            Choose Category...
                                        </option>
                                        {category?.map((res, index) => (
                                            <option key={index} value={res?.categoryId}>
                                                {res?.categoryText}
                                            </option>
                                        ))}
                                    </select>
                                    {formInputError?.productCategory && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {formInputError?.productCategory}
                                        </div>
                                    )}
                                </div>

                                <div className="col-12 col-md-6">
                                    <label for="address_url" className="form-label">
                                        Address
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${formInputError?.address ? "is-invalid" : ""
                                            }`}
                                        id="address_url"
                                        value={formInput?.address || ""}
                                        name="address"
                                        onChange={inputChange}
                                    />
                                    {formInputError?.address && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {formInputError?.address}
                                        </div>
                                    )}
                                </div>

                                <div className="col-12 col-md-6">
                                    <label for="website_url" className="form-label">
                                        Website Url
                                    </label>
                                    <div className="input-group has-validation">
                                        <input
                                            type="url"
                                            className={`form-control ${formInputError?.website ? "is-invalid" : ""
                                                }`}
                                            id="website_url"
                                            value={formInput?.website || ""}
                                            name="website"
                                            onChange={inputChange}
                                        />
                                        {formInputError?.website && (
                                            <div
                                                className="invalid-feedback"
                                                style={{ display: "block" }}
                                            >
                                                {formInputError?.website}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <label for="facebook_url" className="form-label">
                                        Facebook Url
                                    </label>
                                    <div className="input-group has-validation">
                                        <input
                                            type="url"
                                            className={`form-control ${formInputError?.facebook ? "is-invalid" : ""
                                                }`}
                                            id="facebook_url"
                                            value={formInput?.facebook || ""}
                                            name="facebook"
                                            onChange={inputChange}
                                        />
                                        {formInputError?.facebook && (
                                            <div
                                                className="invalid-feedback"
                                                style={{ display: "block" }}
                                            >
                                                {formInputError?.facebook}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <label for="instagram_url" className="form-label">
                                        Instagram Url
                                    </label>
                                    <div className="input-group has-validation">
                                        <input
                                            type="url"
                                            className={`form-control ${formInputError?.instagram ? "is-invalid" : ""
                                                }`}
                                            id="instagram_url"
                                            value={formInput?.instagram || ""}
                                            name="instagram"
                                            onChange={inputChange}
                                        />
                                        {formInputError?.instagram && (
                                            <div
                                                className="invalid-feedback"
                                                style={{ display: "block" }}
                                            >
                                                {formInputError?.instagram}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <label for="email_address" className="form-label">
                                        Email Address
                                    </label>
                                    <div className="input-group has-validation">
                                        <input
                                            type="url"
                                            className={`form-control ${formInputError?.email ? "is-invalid" : ""
                                                }`}
                                            id="email_address"
                                            value={formInput?.email || ""}
                                            name="email"
                                            onChange={inputChange}
                                        />
                                        {formInputError?.email && (
                                            <div
                                                className="invalid-feedback"
                                                style={{ display: "block" }}
                                            >
                                                {formInputError?.email}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="col-12">
                                    <input
                                        className="w-100 btn btn-primary btn-lg"
                                        name=""
                                        value="Save Product"
                                        type="submit"
                                        onClick={formSubmit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer autoClose={3000} />

            {loading && <FullPageLoading />}
            {updatePopUp &&
                <ConformPopUp
                    onClose={() => setUpdatePopUp(false)}
                    submit={() => {
                        setUpdatePopUp(false)
                        submit()
                    }}
                    title=""
                    textMsg={`Do you want to confirm the creation of this product?`}
                />
            }
        </>
    );
};

export default AddProduct;
