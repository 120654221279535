import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
// import category from "../important/category.json";
import { apiBaseUrl, apiBaseUrl2, apiUrl, categoryList, isValidIndiaPhone, pageRoutes, phoneFormate, trimString } from "../important/commanFunction";
import FullPageLoading from "./FullPageLoading";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Typeahead } from "react-bootstrap-typeahead";
import cityName from "../important/city.json";
import SearchEdit from './SearchEventEdit'
import messageShow from "../important/message.json"

const Pagination = ({ data, newPage }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
   
    useEffect(() => {
        setCurrentPage(1)
    }, [data])
    useEffect(() => {
        newPage(currentPage)
    }, [currentPage])

    const totalPages = Math.ceil(data.length / recordsPerPage);
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleNextClick = () => {
        const nextPage = currentPage + 1;
        if (nextPage <= totalPages) {
            setCurrentPage(nextPage);
        }
    };

    const handlePreviousClick = () => {
        const previousPage = currentPage - 1;
        if (previousPage >= 1) {
            setCurrentPage(previousPage);
        }
    };

    const renderPagination = () => {
        const pageNumbers = [];
        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(totalPages, currentPage + 2);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(<span key={i} onClick={() => handlePageChange(i)} className={`page-item page-link ${currentPage === i && 'active'}`}>{i}</span>);
        }

        return (
            <div className=" d-flex justify-content-center mt-5">
                <div className="pagination mx-auto">

                    <span className={`page-item page-link  ${currentPage === 1 && 'disabled'}`} onClick={handlePreviousClick}>  Previous</span>
                    {pageNumbers}

                    <span onClick={handleNextClick} className={`page-item page-link ${(totalPages === 0 || currentPage === totalPages) && 'disabled'}`}>   Next</span>
                </div>
            </div>
        );
    };

    const renderRecords = () => {
        const startIndex = (currentPage - 1) * recordsPerPage;
        const endIndex = startIndex + recordsPerPage;
        const records = data.slice(startIndex, endIndex);

        return (
            <div className="records">
                {records.map((record) => (
                    <div key={record.id}>
                        {/* Display record data here */}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div>
            {renderPagination()}
            {renderRecords()}
        </div>
    );
};

const Search = () => {
    const [category, setCategory] = useState([]);

    useEffect(() => {
        setCategory(categoryList)
     
    //   axios
    //     .get(
    //       apiBaseUrl2 + apiUrl.getCategorySubCategory
    //     )
    //     .then((response) => {
    //       setCategory(response.data)
    //     })
    //     .catch((error) => {
    //       console.error("Error:", error);
    //     })
    //     .finally(() => {
    //       // setLoading(false);
    //     });
    }, [categoryList])
    const cardTypeList = [
        {
            value: "BUSINESS",
            name: "Business"
        },
        {
            value: "EVENT",
            name: "Event"
        },
        {
            value: "HERO",
            name: "Hero"
        },
        {
            value: "MESSAGE",
            name: "Message"
        },
        {
            value: "PAGE",
            name: "Page"
        },
        {
            value: "PRODUCT",
            name: "Product"
        },
    ]

    const [formInput, setFormInput] = useState({});
    const [searchList, setSearchList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [formInputError, setFormInputError] = useState({});

    const navigate = useNavigate();
    const startIndex = (currentPage - 1) * 10;
    const endIndex = startIndex + 10;
    const { sessionToken, id } = JSON.parse(localStorage.getItem("userData"));
    const logOut = () => {
        localStorage.removeItem("userData");
        navigate(pageRoutes.login);
    };

    const inputChange = (e) => {
        if (e.target.name === "phone") {
            setFormInput({
                ...formInput,
                [e.target.name]: phoneFormate(e.target.value),
            });
        } else if (e.target.name === "startDate") {
            setFormInput({
                ...formInput,
                [e.target.name]: e.target.value,
                // endDate: "",
            });
        } else if (e.target.name === "cardId") {
            const numericValue = e.target.value.replace(/[^0-9]/g, "");
            setFormInput({
                ...formInput,
                [e.target.name]: numericValue,
            });
        } else {
            setFormInput({
                ...formInput,
                [e.target.name]: e.target.value,
            });
        }
    };

    const validateForm = (data) => {
        const errors = {};

        if (!data.phone) {
            // errors.phone = messageShow.error.phone_req;
        } else if (!isValidIndiaPhone(data.phone)) {
            errors.phone = messageShow.error.valid_phone;
        }
        // if (!data.cardId) {
        //     errors.cardId = "Business Id  is required";
        // }

        // if (!data.productCategory) {
        //     errors.productCategory = "Category is required";
        // }

        // if (!data.city) {
        //     errors.city = "City is required";
        // }
        return errors;
    };

    const searchValue = () => {
        const validationErrors = validateForm(formInput);
        if (Object.keys(validationErrors).length === 0) {

            if (id && sessionToken) {
                setLoading(true);
                const headers = {
                    Accept: "*/*",
                    "Content-Type": "application/json",
                };

                axios
                    .post(
                        apiBaseUrl + apiUrl.getEventsSearchResults,
                        {
                            eventTitle: formInput?.title || "",
                            countryCode: 91,
                            categoryId: formInput?.productCategory || "",
                            cityId: formInput?.city || "",
                            phoneNumber: formInput?.phone ? formInput?.phone.slice(-10) : '',
                            adminUserId: id,
                            sessionToken: sessionToken,
                            businessId: formInput?.cardId || "",
                        },
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        if (response?.data?.responseType === "SUCCESS") {
                            // toast.success("Successfully Campaign Add");
                            // setFormInput({})
                            setSearchList(response?.data?.userEventVOS || [])
                        } else {
                            toast.error(
                                messageShow.error.something_wrong
                            );
                        }
                    })
                    .catch((error) => {
                        toast.error(messageShow.error.something_wrong);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                logOut();
            }
        }
        setFormInputError(validationErrors);
    }

    const paginastion = useMemo(() => {
        return (
            <Pagination data={searchList}
                newPage={(val) => setCurrentPage(val)}
            />
        )

    }, [searchList])

    const [editDetail, setEditDetail] = useState({})
    const [editId, setEditId] = useState(null)
    
  const [suggestedCities, setSuggestedCities] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedCity, setSelectedCity] = useState(null);

  const handleInputChange = (e) => {
    setFormInput({
      ...formInput,
      cityName: '',
      city: '',
    });
    const value = e.target.value;
    setInputValue(value);

    const filteredCities = cityName?.cityNameList.filter(city =>
      city.cityName.toLowerCase().includes(trimString(value).toLowerCase())
    );

    setSuggestedCities(filteredCities);
  };

  const handleCityClick = (city) => {
    setSelectedCity(city);
    setInputValue(city.cityName);
    setFormInput({
      ...formInput,
      cityName: city.cityName,
      city: city.cityId,
    });
    setSuggestedCities([]);
  };

    return <>
        {editId ? (
            <div className="search-result col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-4">
                <button className="btn btn-primary" onClick={() => setEditId(null)}> Back </button>
                <hr />
                <SearchEdit data={editDetail || {}} />
            </div>
        ) : (
            <>
                <div className={`search-result col-md-9 ms-sm-auto col-lg-10 px-md-4 ${loading && "disabled"
                    }`}>
                    <div className="row">
                        <div className="col-12">
                            <div className="col-12 py-3">
                                <h1>Search Event</h1>
                            </div>
                            <div className="search-form needs-validation" >
                                <div className="form-group">
                                    <input type="text" className="form-control" id="inputGroupFile04"
                                        aria-describedby="inputGroupFileAddon04" placeholder="Search by phone" value={formInput?.phone || ""}
                                        name="phone"
                                        onChange={inputChange} />
                                    {formInputError?.phone && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {formInputError?.phone}
                                        </div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="inputGroupFile04"
                                        aria-describedby="inputGroupFileAddon04" placeholder="Search by Event name" value={formInput?.title || ""}
                                        name="title"
                                        onChange={inputChange} />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="inputGroupFile054"
                                        aria-describedby="inputGroupFileAddon04" placeholder="Search by Business Id" value={formInput?.cardId || ""}
                                        name="cardId"
                                        onChange={inputChange} />
                                    {formInputError?.cardId && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {formInputError?.cardId}
                                        </div>
                                    )}
                                </div>
                                {/* <div className="form-group">
                            <input type="text" className="form-control" id="inputGroupFile04"
                                aria-describedby="inputGroupFileAddon04" placeholder="Search by description" value={formInput?.description || ""}
                                name="description"
                                onChange={inputChange} />
                        </div> */}
                                <div className="form-group">
                                    <select
                                        className={`form-select ${formInputError?.productCategory ? "is-invalid" : ""
                                            }`}
                                        id="category"

                                        value={formInput?.productCategory || ""}
                                        name="productCategory"
                                        onChange={inputChange}
                                    >
                                        <option value="" disabled selected>
                                            Choose Category...
                                        </option>
                                        {category?.map((res, index) => (
                                            <option key={index} value={res?.categoryId}>
                                                {res?.categoryText}
                                            </option>
                                        ))}
                                    </select>
                                    {formInputError?.productCategory && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {formInputError?.productCategory}
                                        </div>
                                    )}
                                </div>

                                <div className="form-group">


{/* <label for="selections_example" className="form-label">City</label> */}
<input
  className="form-control"
  type="text"
  value={inputValue}
  onChange={handleInputChange}
  onBlur={() => {
    setTimeout(() => {
      setSuggestedCities([])
    }, 500)
  }
  }
  placeholder="Type a city name"
/>
<div style={{
  width: "100%",
  position: "relative"
}}>
  {suggestedCities?.length > 0 && <ul style={{
    position: "absolute",
    background: "#fff",
    border: "solid 1px #ddd",
    maxHeight: "150px",
    overflow: "auto",
    listStyle: "none",
    padding: "10px",
    width: "100%"
  }}>
    {suggestedCities?.map(city => (
      <li key={city.cityId} onClick={() => handleCityClick(city)}>
        {city.cityName}
      </li>
    ))}
  </ul>}
</div>
{formInputError?.city && (
  <div
    className="invalid-feedback"
    style={{ display: "block" }}
  >
    {formInputError?.city}
  </div>
)}
</div>
                                {/* <div className="form-group">
                            <select className="form-select" id="type"
                                value={formInput?.cardType || ""}
                                name="cardType"
                                onChange={inputChange}>
                                <option value="" disabled selected>Select a type</option>
                                {cardTypeList?.map((res, index) => (
                                    <option key={index} value={res?.value}>
                                        {res?.name}
                                    </option>
                                ))}
                            </select>
                            {formInputError?.cardType && (
                                <div
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                >
                                    {formInputError?.cardType}
                                </div>
                            )}
                        </div> */}
                                <div className="form-group">
                                    <button className="btn btn-primary w-100" type="button"
                                        id="inputGroupFileAddon04" onClick={searchValue}>Search</button>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-hover mt-4">
                                    <thead >
                                        <th>ID</th>
                                        <th>Title</th>
                                        <th>City Name</th>
                                        <th>Event Type</th>
                                        <th>Category</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </thead>
                                    <tbody>
                                        {searchList?.slice(startIndex, endIndex)?.map((res, index) =>
                                            <tr key={index}>
<td>{res?.userEventId}</td>
                                                <td>{res?.eventTitle}</td>
                                                <td>{res?.cityVO?.cityName}</td>
                                                <td>{res?.userEventType}</td>
                                                <td>{res?.eventCategory?.name}</td>
                                                <td>
                                                    <span
                                                        className="badge bg-primary-subtle text-primary-emphasis rounded-pill">{res?.userEventStatus}</span>
                                                </td>
                                                <td>
                                                    <button className="btn btn-primary"
                                                        onClick={() => {
                                                            setEditId(res?.userEventId)
                                                            setEditDetail(res)
                                                            // navigate(`${pageRoutes.searchEditUrl}?cardAction=${res?.cardAction}&cardId=${res?.resultItemId}`)
                                                        }
                                                        }>Edit</button>
                                                </td>
                                            </tr>
                                        )}

                                    </tbody>
                                </table>
                                <div>
                                    {searchList?.length === 0 && (
                                        <div className="list-loading-main-div">
                                            <p className="no_record"> Record not found </p>
                                        </div>
                                    )}
                                </div>
                                {paginastion}

                            </div>
                        </div>
                    </div>

                </div>
                <ToastContainer autoClose={3000} />
                {loading && <FullPageLoading />} </>)}
    </>;
};



export default Search;
