import { useEffect, useState } from "react";
import {
    apiBaseUrl,
    apiUrl,
    apiBaseUrl2,
    dateSendFormate2,
    imagetypeError,
    isImageType,
    isValidUrl,
    isNumeric,
    pageRoutes,
    categoryList,
} from "../important/commanFunction";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import category from "../important/category.json";
import FullPageLoading from "./FullPageLoading";
import { ToastContainer, toast } from "react-toastify";
import ConformPopUp from "./ConformPopUP";
import socialName from "../important/socialName.json";
import messageShow from "../important/message.json"

const ContentAdd = () => {
    const [formInput, setFormInput] = useState({});
    const [loading, setLoading] = useState(false);
    const [formInputError, setFormInputError] = useState({});
    const navigate = useNavigate();
    const { sessionToken, id } = JSON.parse(localStorage.getItem("userData"));

    const [category, setCategory] = useState([]);

    useEffect(() => {
        setCategory(categoryList)
        // axios
        //   .get(
        //     apiBaseUrl2 + apiUrl.getCategorySubCategory
        //   )
        //   .then((response) => {
        //     setCategory(response.data || [])
        //   })
        //   .catch((error) => {
        //     console.error("Error:", error);
        //   })
        //   .finally(() => {
        //     // setLoading(false);
        //   });
    }, [categoryList])

    const logOut = () => {
        localStorage.removeItem("userData");
        navigate(pageRoutes.login);
    };

    const inputChange = (e) => {
        if (e.target.name === "startDate") {
            setFormInput({
                ...formInput,
                [e.target.name]: e.target.value,
                // endDate: "",
            });
        } else {
            setFormInput({
                ...formInput,
                [e.target.name]: e.target.value,
            });
        }
    };

    const fileChange = (e) => {
        setFormInput({
            ...formInput,
            [e.target.name]: e.target.files[0],
        });
    };

    const validateForm = (data) => {
        const errors = {};

        if (!data.title) {
            errors.title = messageShow.error.title_req;
        }

        if (!data.description) {
            errors.description = messageShow.error.description_req;
        }

        const startDateObject = new Date(data.startDate);
        const endDateObject = new Date(data.endDate);

        if (!data.endDate) {
            errors.endDate = messageShow.error.end_date_req;
        } else if (startDateObject > endDateObject) {
            errors.endDate = messageShow.error.end_date_less;
        }

        if (!data.startDate) {
            errors.startDate = messageShow.error.start_date_req;
        }

        if (!data.productCategory) {
            errors.productCategory = messageShow.error.category_req;
        }

        if (!data.link) {
            errors.link = messageShow.error.link_url_req;
        } else if (data.link && !isValidUrl(data?.link)) {
            errors.link = messageShow.error.valid_url;
        }

        if (!data.imageUrl) {
            // errors.imageUrl = "imageUrl url is required";
        } else if (data.imageUrl && !isValidUrl(data?.imageUrl)) {
            errors.imageUrl = messageShow.error.valid_url;
        }

        if (!data.imageUrl) {
            if (!data.productImage) {
                errors.productImage = messageShow.error.image_req;
            } else if (!isImageType(data?.productImage?.name)) {
                errors.productImage = imagetypeError;
            }
        }

        if (!data.social_media) {
            errors.social_media = messageShow.error.source_req;
        }

        return errors;
    };
    const headers = {
        Accept: "*/*",
        "Content-Type": "application/json",
    };

    const createNewCampain = (img) => {
        axios
            .post(
                apiBaseUrl + apiUrl.addContent,
                {
                    contentSource: formInput?.social_media,
                    link: formInput?.link,
                    categoryId: formInput?.productCategory,
                    featuredImage: img,
                    description: formInput?.description,
                    endDate: dateSendFormate2(formInput?.endDate),
                    adminUserId: id,
                    sessionToken: sessionToken,
                    startDate: dateSendFormate2(formInput?.startDate),
                    title: formInput?.title,
                },
                {
                    headers: headers,
                }
            )
            .then((response) => {
                if (response?.data?.responseType === "SUCCESS") {
                    toast.success(messageShow.success.content_add);
                    setFormInput({})
                } else {
                    toast.error(
                        messageShow.error.something_wrong
                    );
                }
            })
            .catch((error) => {
                toast.error(messageShow.error.something_wrong);
            })
            .finally(() => {
                setLoading(false);
            });
    }
    const fileUploadRequest = () => {
        axios
            .post(
                apiBaseUrl + apiUrl.getUploadFileUrl,
                {
                    cardType: 'EVENT',
                    expirationDate: dateSendFormate2(formInput?.endDate),
                    adminUserId: id,
                    sessionToken: sessionToken,
                    fileName: formInput?.productImage?.name,
                }, {
                headers: headers,
            }
            )
            .then((response) => {
                if (response?.status === 200) {
                    const contentType = response?.data?.mimeType
                    const uploadUrl = response?.data?.url;
                    const imageUrl = response?.data?.fileAccessUrl;

                    let data = formInput?.productImage;

                    let config = {
                        method: 'put',
                        maxBodyLength: Infinity,
                        url: uploadUrl,
                        headers: {
                            'Content-Type': contentType
                        },
                        data: data
                    };

                    axios.request(config)
                        .then((response) => {
                            if (response.status === 200) {

                                createNewCampain(imageUrl)

                            } else {
                                setLoading(false);
                                toast.error(messageShow.error.something_wrong);
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            toast.error(messageShow.error.something_wrong);
                        });


                }
                else {
                    setLoading(false);
                    toast.error(
                        messageShow.error.something_wrong
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error(messageShow.error.something_wrong);
            })
    }
    const submit = () => {
        if (id && sessionToken) {
            setLoading(true);

            if (formInput?.productImage?.name && !formInput?.imageUrl) {
                fileUploadRequest()
            } else {
                createNewCampain(formInput?.imageUrl || '')
            }
        } else {
            logOut();
        }
    }
    const [updatePopUp, setUpdatePopUp] = useState(false)

    const formSubmit = async () => {
        const validationErrors = validateForm(formInput);
        if (Object.keys(validationErrors).length === 0) {
            setUpdatePopUp(true)
        }
        setFormInputError(validationErrors);
    };
    return (
        <>
            <div
                className={`col-md-9 ms-sm-auto col-lg-10 px-md-4 ${loading && "disabled"
                    }`}
            >
                <div className="row">
                    <div className="col-12 col-md-8 col-lg-6 mx-auto mb-5">
                        <div className="col-12 py-3">
                            <h1>Add Content</h1>
                        </div>
                        <div className="needs-validation">
                            <div className="row g-3">
                                <div className="col-12">
                                    <label for="imageUrl" className="form-label">
                                        Image Url
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${formInputError?.imageUrl ? "is-invalid" : ""
                                            }`}
                                        id="imageUrl"
                                        placeholder=""
                                        value={formInput?.imageUrl || ""}
                                        name="imageUrl"
                                        onChange={inputChange}
                                    />
                                    {formInputError?.imageUrl && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {formInputError?.imageUrl}
                                        </div>
                                    )}
                                </div>
                                {!formInput?.imageUrl &&
                                    <div className="col-12">
                                        <label for="upload_image" className="form-label">
                                            Upload Content Image
                                        </label>
                                        <div className="input-group has-validation">
                                            <input
                                                accept="image/*"
                                                name="productImage"
                                                type="file"
                                                className={`form-control ${formInputError?.productImage ? "is-invalid" : ""
                                                    }`}
                                                id="upload_image"
                                                required
                                                onChange={fileChange}
                                            />
                                            {formInputError?.productImage && (
                                                <div
                                                    className="invalid-feedback"
                                                    style={{ display: "block" }}
                                                >
                                                    {formInputError?.productImage}
                                                </div>
                                            )}
                                        </div>
                                    </div>}

                                <div className="col-12 col-md-12">
                                    <label for="social_media" className="form-label">
                                    Content Source
                                    </label>

                                    <select
                                        className={`form-select ${formInputError?.social_media ? "is-invalid" : ""
                                            }`}
                                        id="social_media"
                                        required
                                        value={formInput?.social_media || ""}
                                        name="social_media"
                                        onChange={inputChange}
                                    >
                                        <option value="" disabled selected>
                                            Choose Content Source...
                                        </option>
                                        {socialName?.social_media?.map((res, index) => (
                                            <option key={index} value={res?.value}>
                                                {res?.name}
                                            </option>
                                        ))}
                                    </select>
                                    {formInputError?.social_media && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {formInputError?.social_media}
                                        </div>
                                    )}
                                </div>

                                <div className="col-12">
                                    <label for="title" className="form-label">
                                        Title
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${formInputError?.title ? "is-invalid" : ""
                                            }`}
                                        id="title"
                                        placeholder=""
                                        value={formInput?.title || ""}
                                        name="title"
                                        onChange={inputChange}
                                    />
                                    {formInputError?.title && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {formInputError?.title}
                                        </div>
                                    )}
                                </div>
                                <div className="col-12">
                                    <label for="desc" className="form-label">
                                        Description
                                    </label>
                                    <textarea
                                        value={formInput?.description || ""}
                                        name="description"
                                        onChange={inputChange}
                                        id="desc"
                                        cols="30"
                                        rows="5"
                                        className={`form-control ${formInputError?.description ? "is-invalid" : ""
                                            }`}
                                        required
                                    ></textarea>
                                    {formInputError?.description && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {formInputError?.description}
                                        </div>
                                    )}
                                </div>
                                <div className="col-12">
                                    <label for="link" className="form-label">
                                        Link
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${formInputError?.link ? "is-invalid" : ""
                                            }`}
                                        id="link"
                                        placeholder=""
                                        value={formInput?.link || ""}
                                        name="link"
                                        onChange={inputChange}
                                    />
                                    {formInputError?.link && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {formInputError?.link}
                                        </div>
                                    )}
                                </div>
                                <div className="col-12 col-md-12">
                                    <label for="category" className="form-label">
                                        Category
                                    </label>

                                    <select
                                        className={`form-select ${formInputError?.productCategory ? "is-invalid" : ""
                                            }`}
                                        id="category"
                                        required
                                        value={formInput?.productCategory || ""}
                                        name="productCategory"
                                        onChange={inputChange}
                                    >
                                        <option value="" disabled selected>
                                            Choose Category...
                                        </option>
                                        {category?.map((res, index) => (
                                            <option key={index} value={res?.categoryId}>
                                                {res?.categoryText}
                                            </option>
                                        ))}
                                    </select>
                                    {formInputError?.productCategory && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {formInputError?.productCategory}
                                        </div>
                                    )}
                                </div>{" "}
                                <div className="col-12 col-sm-6">
                                    <label for="start_date" className="form-label">
                                        Start Date
                                    </label>
                                    <input
                                        value={formInput?.startDate || ""}
                                        name="startDate"
                                        onChange={inputChange}
                                        type="datetime-local"
                                        className={`form-control ${formInputError?.startDate ? "is-invalid" : ""
                                            }`}
                                        id="start_date"
                                        placeholder=""
                                        required=""
                                    />
                                    {formInputError?.startDate && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {formInputError?.startDate}
                                        </div>
                                    )}
                                </div>
                                <div className="col-12 col-sm-6">
                                    <label for="end_date" className="form-label">
                                        End Date
                                    </label>
                                    <input
                                        type="datetime-local"
                                        className={`form-control ${formInputError?.endDate ? "is-invalid" : ""
                                            }`}
                                        id="end_date"
                                        placeholder=""
                                        min={
                                            formInput.startDate &&
                                            new Date(
                                                new Date(formInput.startDate).getTime() + 86400000
                                            )
                                                .toISOString()
                                                .split("T")[0]
                                        }
                                        value={formInput?.endDate || ""}
                                        name="endDate"
                                        onChange={inputChange}
                                        required=""
                                    />
                                    {formInputError?.endDate && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {formInputError?.endDate}
                                        </div>
                                    )}
                                </div>
                                <div className="col-12">
                                    <input
                                        className="w-100 btn btn-primary btn-lg"
                                        name=""
                                        value="Save Content"
                                        type="submit"
                                        onClick={formSubmit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer autoClose={3000} />

            {loading && <FullPageLoading />}

            {updatePopUp &&
                <ConformPopUp
                    onClose={() => setUpdatePopUp(false)}
                    submit={() => {
                        setUpdatePopUp(false)
                        submit()
                    }}
                    title=""
                    textMsg={`Do you want to confirm the creation of this content?`}
                />
            }
        </>
    );
};

export default ContentAdd;
