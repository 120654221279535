import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Loading from "./Loading";
import { pageRoutes } from "../important/commanFunction";

const ProtectedRoute = (Component) => {
  const Auth = (props) => {

    // return <Component {...props} />
    const [show, setShow] = useState("");
    const navigate = useNavigate();
    useEffect(() => {
      const isLoggedIn =
        JSON.parse(localStorage.getItem("userData"))?.token;
      setShow(isLoggedIn ? "component" : "");
      if (!isLoggedIn) navigate(pageRoutes.login);
    });

    return (
      <>
        {show != "component" && <Loading />}
        {show == "component" && <Component {...props} />}
      </>
    );
  };

  return Auth;
};

export default ProtectedRoute;
