import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import category from "../important/category.json";
import FullPageLoading from "./FullPageLoading";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import cityName from "../important/city.json";
import SearchAddCampainPopUp from './SearchAddCampainPopUP'
import {
    apiBaseUrl, apiBaseUrl2,
    apiUrl,
    categoryList,
    imagetypeError,
    isImageType,
    isValidEmail,
    isValidIndiaPhone,
    isValidUrl,
    pageRoutes,
    phoneFormate,
    trimString,
} from "../important/commanFunction";
import messageShow from "../important/message.json"

import ConformPopUp from "./ConformPopUP";
const SearchEdit = ({ data }) => {
    const [detailsUpdate, setDetailsUpdate] = useState({})
    const [updateInputError, setUpdateInputError] = useState({});
    const [googleAddress, setGoogleAddress] = useState({})
    const [loading, setLoading] = useState(false);
    const [addCampain, setAddCampain] = useState(false);
    const { sessionToken, id } = JSON.parse(localStorage.getItem("userData"));
    const [inputValue, setInputValue] = useState('');
    const [selectedCity, setSelectedCity] = useState(null);
    const [suggestedCities, setSuggestedCities] = useState([]);
    const navigate = useNavigate();
    const [tagsList, setTagsList] = useState([]);
    const [category, setCategory] = useState([]);

    useEffect(() => {
        setCategory(categoryList)
      
    //   axios
    //     .get(
    //       apiBaseUrl2 + apiUrl.getCategorySubCategory
    //     )
    //     .then((response) => {
    //       setCategory(response.data)
    //     })
    //     .catch((error) => {
    //       console.error("Error:", error);
    //     })
    //     .finally(() => {
    //       // setLoading(false);
    //     });
    }, [categoryList])

    useEffect(() => {
        setInputValue(data?.cityVO?.cityName || '');
        setDetailsUpdate({
            contactNumber: data?.businessContactNumber || '',
            description: data?.businessDescription || '',
            name: data?.businessName || '',
            streetAddress: data?.businessStreetAddress || '',
            title: data?.businessTitle || '',
            categoryId: data?.businessCategory?.id || 0,
            cityId: data?.cityVO?.cityId || 0,
            cityName: data?.cityVO?.cityName || 0,
            email: data?.businessEmail || '',
            expiredOrDisabled: data?.userBusinessEnabled || false,
            facebookLink: data?.facebookLink || '',
            instagramLink: data?.instagramLink,
            website: data?.businessWebsite || '',
            imageUrl: data?.businessImageLocation || '',
            businessLocation: data?.userBusinessLocation || '',
            selectedTags: data?.selectedTags || []


            // cardLink: "https://listy.city/details?type=business&slug=zoho-creator-development-300",
            // categoryId: "5",
            // cityId: 31,
            // cityName: "Faridabad",
            // contactNumber: "9648476327",
            // description: "da",
            // email: "s",
            // endDate: "2024-02-23",
            // eventLink: "hghh",
            // facebookLink: "https://listy.city/details?type=business&slug=zoho-creator-development-300",
            // instagramLink: "https://listy.city/details?type=business&slug=zoho-creator-development-300",
            // name: "asdfssssssd",
            // portfolioLink: "ooijk",
            // price: "78",
            // startDate: "2024-02-09",
            // streetAddress: "jkhk",
            // title: "s",
            // website: "https://listy.city/details?type=business&slug=zoho-creator"
        })
        setGoogleAddress({
            address: data?.businessAddress,
            latitude: data?.latitude || 0,
            longitude: data?.longitude || 0,
        })
    }, [data])

    const logOut = () => {
        localStorage.removeItem("userData");
        navigate(pageRoutes.login);
    };

    const headers = {
        Accept: "*/*",
        "Content-Type": "application/json",
    };

    useEffect(() => {
        const initAutocomplete = () => {
            const input = document.getElementById('autocomplete_google');
            const autocomplete_google = new window.google.maps.places.Autocomplete(input);

            window.google.maps.event.addListener(autocomplete_google, 'place_changed', () => {
                const place = autocomplete_google.getPlace();
                setGoogleAddress({
                    ...googleAddress,
                    address: place?.formatted_address,
                    latitude: place?.geometry?.location?.lat(),
                    longitude: place?.geometry?.location?.lng(),
                });
            });
        };

        initAutocomplete();
    }, []);

    const validateUpdateForm = (data) => {
        console.log(data)
        const errors = {};

        // if (!data.cardType) {
        //     errors.cardType = "Card Type is required";
        // }

        if (!data.categoryId) {
            errors.categoryId = "Category is required";
        }
        if (!data.businessLocation) {
            errors.businessLocation = "Business Location is required";
        }
        if (!data.selectedTags || data?.selectedTags?.length === 0) {
            errors.selectedTags = "Sub Category is required";
        }

        // if (!data.price) {
        //     errors.price = "price is required";
        // }
        if (!data.cityName) {
            errors.cityName = "City is required";
        }

        if (!data.contactNumber) {
            errors.contactNumber = messageShow.error.phone_req;
        } else if (!isValidIndiaPhone(data.contactNumber)) {
            errors.contactNumber = messageShow.error.valid_phone;
        }

        if (!data.title) {
            errors.title = messageShow.error.title_req;
        }
        if (!data.name) {
            errors.name = messageShow.error.name_req;
        }

        if (!data.address) {
            errors.address = messageShow.error.address_req;
        }
        // if (!data.streetAddress) {
        //     errors.streetAddress = "street Address is required";
        // }

        if (!data.description) {
            errors.description = messageShow.error.description_req;
        }

        // const startDateObject = new Date(data.startDate);
        // const endDateObject = new Date(data.endDate);

        // if (!data.endDate) {
        //     errors.endDate = messageShow.error.end_date_req;
        // } else if (startDateObject > endDateObject) {
        //     errors.endDate = messageShow.error.end_date_less;
        // }

        // if (!data.startDate) {
        //     errors.startDate = messageShow.error.start_date_req;
        // }

        if (!data.website) {
            // errors.website = "Website url is required";
        } else if (data.website && !isValidUrl(data?.website)) {
            errors.website = messageShow.error.valid_url;
        }

        if (!data.facebookLink) {
            // errors.facebookLink = "Facebook url is required";
        } else if (data.facebookLink && !isValidUrl(data?.facebookLink)) {
            errors.facebookLink = messageShow.error.valid_url;
        }
        // if (!data.portfolioLink) {
        //     errors.portfolioLink = "Portfolio Link  is required";
        // } else if (data.portfolioLink && !isValidUrl(data?.portfolioLink)) {
        //     errors.portfolioLink = messageShow.error.valid_url;
        // }

        // if (!data.eventLink) {
        //     errors.eventLink = "Event url is required";
        // } else if (data.eventLink && !isValidUrl(data?.eventLink)) {
        //     errors.eventLink = messageShow.error.valid_url;
        // }

        // if (!data.cardLink) {
        //     errors.cardLink = "Card url is required";
        // } else if (data.cardLink && !isValidUrl(data?.cardLink)) {
        //     errors.cardLink = messageShow.error.valid_url;
        // }

        if (!data.instagramLink) {
            // errors.instagramLink = "Instagram url is required";
        } else if (data.instagramLink && !isValidUrl(data?.instagramLink)) {
            errors.instagramLink = messageShow.error.valid_url;
        }

        //   if (!data.productImage) {
        //     errors.productImage = "Product Image  is required";
        //   } else if (!isImageType(data?.productImage?.name)) {
        //     errors.productImage = imagetypeError;
        //   }

        if (!data.email) {
            errors.email = "Email is required";
        } else if (!isValidEmail(data.email)) {
            errors.email = messageShow.error.valid_email;
        }

        return errors;
    };

    function getTagsByCategoryId(categoryId) {
        const selectedCategory = category?.filter(
            (category) => category?.categoryId == categoryId
        );

        if (selectedCategory[0]) {
            return selectedCategory[0]?.tags;
        }

        return [];
    }

    useEffect(() => {
        detailsUpdate?.categoryId &&
            setTagsList(getTagsByCategoryId(detailsUpdate?.categoryId));
    }, [detailsUpdate?.categoryId]);

    const updateInputChange = (e) => {
        if (e.target.name === "startDate") {
            setDetailsUpdate({
                ...detailsUpdate,
                [e.target.name]: e.target.value,
                // endDate: "",
            });
        } else if (e.target.name === "cardId") {
            const numericValue = e.target.value.replace(/[^0-9]/g, "");
            setDetailsUpdate({
                ...detailsUpdate,
                [e.target.name]: numericValue,
            });
        } else if (e.target.name === "price") {
            const numericValue = e.target.value.replace(/[^0-9]/g, "");
            setDetailsUpdate({
                ...detailsUpdate,
                [e.target.name]: numericValue,
            });
        } else if (e.target.name === "contactNumber") {
            setDetailsUpdate({
                ...detailsUpdate,
                [e.target.name]: phoneFormate(e.target.value),
            });
        }
        else if (e.target.name === "categoryId") {
            setDetailsUpdate({
                ...detailsUpdate,
                [e.target.name]: e.target.value,
                selectedTags: []
            });
        }
        else {
            setDetailsUpdate({
                ...detailsUpdate,
                [e.target.name]: e.target.value,
            });
        }
    }
    const updateData2 = () => {
        if (id && sessionToken) {
            setLoading(true);

            axios
                .post(
                    apiBaseUrl + apiUrl.updateUserBusinessByAdmin,
                    {
                        ...googleAddress,
                        selectedTags: detailsUpdate?.selectedTags || [],
                        businessAddress: googleAddress?.address || '',
                        businessIdToBeUpdated: data?.userBusinessId || 0,
                        imageUrl: detailsUpdate?.imageUrl || '',
                        userBusinessLocation: detailsUpdate?.businessLocation || '',
                        websiteLink: detailsUpdate?.website || '',
                        instagramLink: detailsUpdate?.instagramLink,
                        facebookLink: detailsUpdate?.facebookLink || '',
                        emailAddress: detailsUpdate?.email || '',
                        cityId: detailsUpdate?.cityId || 0,
                        categoryId: detailsUpdate?.categoryId || 0,
                        businessTitle: detailsUpdate?.title || '',
                        businessStreetAddress: detailsUpdate?.streetAddress || '',
                        businessName: detailsUpdate?.name || '',
                        businessDescription: detailsUpdate?.description || '',
                        businessContactNumber: detailsUpdate.contactNumber ? detailsUpdate.contactNumber.slice(-10) : '',
                        updateAction: "UPDATE",
                        adminUserId: id,
                        sessionToken: sessionToken,
                    },
                    {
                        headers: headers,
                    }
                )
                .then((response) => {
                    if (response?.data?.responseType === "SUCCESS") {
                        toast.success("Successfully Update");
                    } else {
                        toast.error(
                            messageShow.error.something_wrong
                        );
                    }
                })
                .catch((error) => {
                    toast.error(messageShow.error.something_wrong);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            logOut();
        }
    }
    const updateData = () => {
        const validationErrors = validateUpdateForm({ ...detailsUpdate, address: googleAddress?.address });
        if (Object.keys(validationErrors).length === 0) {

            setUpdatePopUp(true)
        }
        setUpdateInputError(validationErrors);

        console.log(validationErrors)
    }

    const disableEnable = () => {
        axios
            .post(
                apiBaseUrl + apiUrl.updateUserBusinessByAdmin,
                {
                    ...googleAddress,
                    selectedTags: detailsUpdate?.selectedTags || [],
                    businessAddress: googleAddress?.address || '',
                    businessIdToBeUpdated: data?.userBusinessId || 0,
                    imageUrl: detailsUpdate?.imageUrl || '',
                    userBusinessLocation: detailsUpdate?.businessLocation || '',
                    websiteLink: detailsUpdate?.website || '',
                    instagramLink: detailsUpdate?.instagramLink,
                    facebookLink: detailsUpdate?.facebookLink || '',
                    emailAddress: detailsUpdate?.email || '',
                    cityId: detailsUpdate?.cityId || 0,
                    categoryId: detailsUpdate?.categoryId || 0,
                    businessTitle: detailsUpdate?.title || '',
                    businessStreetAddress: detailsUpdate?.streetAddress || '',
                    businessName: detailsUpdate?.name || '',
                    businessDescription: detailsUpdate?.description || '',
                    businessContactNumber: detailsUpdate.contactNumber ? detailsUpdate.contactNumber.slice(-10) : '',
                    updateAction: detailsUpdate?.expiredOrDisabled ? "DISABLE" : "ENABLE",
                    adminUserId: id,
                    sessionToken: sessionToken,
                },
                {
                    headers: headers,
                }
            )
            .then((response) => {
                if (response?.data?.responseType === "SUCCESS") {
                    toast.success("Successfully Update");
                    setDetailsUpdate({
                        ...detailsUpdate,
                        expiredOrDisabled: !detailsUpdate?.expiredOrDisabled
                    })
                } else {
                    toast.error(
                        messageShow.error.something_wrong
                    );
                }
            })
            .catch((error) => {
                toast.error(messageShow.error.something_wrong);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleInputChange = (e) => {
        console.log("pokok")
        setDetailsUpdate({
            ...detailsUpdate,
            cityName: '',
            cityId: '',
        });
        const value = e.target.value;
        setInputValue(value);

        const filteredCities = cityName?.cityNameList.filter(city =>
            city.cityName.toLowerCase().includes(trimString(value).toLowerCase())
        );

        setSuggestedCities(filteredCities);
    };

    const handleCityClick = (city) => {
        setSelectedCity(city);
        setInputValue(city.cityName);
        setDetailsUpdate({
            ...detailsUpdate,
            cityName: city.cityName,
            cityId: city.cityId,
        });
        setSuggestedCities([]);
    };
    const [disablePopUp, setDisablePopUp] = useState(false)
    const [updatePopUp, setUpdatePopUp] = useState(false)
    return (
        <div style={{ marginBottom: "30px" }} className={` ${loading && "disabled"
            }`}>
            <div className="row">
                <div className="col-12">
                    <div className="col-12 py-3">
                        <h1>
                            Business Update
                        </h1>
                    </div>
                </div>
                <div >
                    <div >
                        <div className="row">
                            <div className="col-12 col-md-6">
                                {detailsUpdate?.imageUrl ? <img src={detailsUpdate.imageUrl} style={{ width: "100%", marginBottom: "20px" }} /> : <p>Image Not Found</p>}
                            </div>
                            <div className="col-12 col-md-6">
                                <h1>Id: ({data?.userBusinessId})</h1>
                                <h1>Status : <span
                                    className="badge bg-primary-subtle text-primary-emphasis rounded-pill">{data?.businessVerificationStatus}</span></h1>
                                <h1>Enabled : <span
                                    className="badge bg-primary-subtle text-primary-emphasis rounded-pill">{detailsUpdate?.expiredOrDisabled ? "true" :"false"}</span></h1>
                            </div>
                        </div>
                        <div className="row g-3">

                            <div className="col-12 col-md-6">
                                <label for="name" className="form-label">Name</label>
                                <input type="text" className="form-control" id="name" placeholder=""
                                    value={detailsUpdate?.name}
                                    onChange={updateInputChange}
                                    name="name"
                                />
                                {updateInputError?.name && (
                                    <div
                                        className="invalid-feedback"
                                        style={{ display: "block" }}
                                    >
                                        {updateInputError?.name}
                                    </div>
                                )}
                            </div>
                            <div className="col-12 col-md-6">
                                <label for="title" className="form-label">Title</label>
                                <input type="text" className="form-control" id="title" placeholder=""
                                    value={detailsUpdate?.title}
                                    onChange={updateInputChange}
                                    name="title"
                                />
                                {updateInputError?.title && (
                                    <div
                                        className="invalid-feedback"
                                        style={{ display: "block" }}
                                    >
                                        {updateInputError?.title}
                                    </div>
                                )}
                            </div>
                            <div className="col-12 col-md-6">
                                <label for="email" className="form-label">Email</label>
                                <input type="text" className="form-control" id="email" placeholder=""
                                    value={detailsUpdate?.email}
                                    onChange={updateInputChange}
                                    name="email"
                                />
                                {updateInputError?.email && (
                                    <div
                                        className="invalid-feedback"
                                        style={{ display: "block" }}
                                    >
                                        {updateInputError?.email}
                                    </div>
                                )}
                            </div>



                            <div className="col-12 col-md-6">
                                <label for="contactNumber" className="form-label">Contact Number</label>
                                <input type="text" className="form-control" id="contactNumber" placeholder=""
                                    value={detailsUpdate?.contactNumber}
                                    onChange={updateInputChange}
                                    name="contactNumber"
                                />
                                {updateInputError?.contactNumber && (
                                    <div
                                        className="invalid-feedback"
                                        style={{ display: "block" }}
                                    >
                                        {updateInputError?.contactNumber}
                                    </div>
                                )}
                            </div>
                            {/* <div className="col-12 col-md-6">
                                <label for="price" className="form-label">Price </label>
                                <input type="text" className="form-control" id="price" placeholder=""
                                    value={detailsUpdate?.price}
                                    onChange={updateInputChange}
                                    name="price"
                                />
                                {updateInputError?.price && (
                                    <div
                                        className="invalid-feedback"
                                        style={{ display: "block" }}
                                    >
                                        {updateInputError?.price}
                                    </div>
                                )}
                            </div> */}

                            <div className="col-12">
                                <label for="desc" className="form-label">Description</label>
                                <textarea value={detailsUpdate?.description}
                                    onChange={updateInputChange} name="description" id="desc" cols="30" rows="2" className="form-control"
                                />
                                {updateInputError?.description && (
                                    <div
                                        className="invalid-feedback"
                                        style={{ display: "block" }}
                                    >
                                        {updateInputError?.description}
                                    </div>
                                )}
                            </div>
                            <div className="col-12 col-md-6">
                                <label for="userBusinessLocation" className="form-label">
                                    Business Location
                                </label>

                                <select
                                    className={`form-select ${updateInputError?.businessLocation ? "is-invalid" : ""
                                        }`}
                                    id="category"
                                    required
                                    value={detailsUpdate?.businessLocation || ""}
                                    name="businessLocation"
                                    onChange={updateInputChange}
                                >
                                    <option value="" disabled selected>
                                        Choose Business Location...
                                    </option>

                                    <option value="ANYWHERE">
                                        Anywhere
                                    </option>
                                    <option value="CITY_AND_NEARBY">
                                        City And Nearby
                                    </option>
                                    <option value="NEAR_MY_LOCATION">
                                        Near My Location
                                    </option>
                                </select>
                                {updateInputError?.businessLocation && (
                                    <div
                                        className="invalid-feedback"
                                        style={{ display: "block" }}
                                    >
                                        {updateInputError?.businessLocation}
                                    </div>
                                )}
                            </div>{" "}
                            {/* <div className="col-12 col-sm-6">
                                <label for="start_date" className="form-label">
                                    Start Date
                                </label>
                                <input
                                    value={detailsUpdate?.startDate || ""}
                                    name="startDate"
                                    onChange={updateInputChange}
                                    type="datetime-local"
                                    className={`form-control ${updateInputError?.startDate ? "is-invalid" : ""
                                        }`}
                                    id="start_date"
                                    placeholder=""
                                    required=""
                                />
                                {updateInputError?.startDate && (
                                    <div
                                        className="invalid-feedback"
                                        style={{ display: "block" }}
                                    >
                                        {updateInputError?.startDate}
                                    </div>
                                )}
                            </div>
                            <div className="col-12 col-sm-6">
                                <label for="end_date" className="form-label">
                                    End Date
                                </label>
                                <input
                                    type="datetime-local"
                                    className={`form-control ${updateInputError?.endDate ? "is-invalid" : ""
                                        }`}
                                    id="end_date"
                                    placeholder=""
                                    min={
                                        detailsUpdate.startDate &&
                                        new Date(
                                            new Date(detailsUpdate.startDate).getTime() + 86400000
                                        )
                                            .toISOString()
                                            .split("T")[0]
                                    }
                                    value={detailsUpdate?.endDate || ""}
                                    name="endDate"
                                    onChange={updateInputChange}
                                    required=""
                                />
                                {updateInputError?.endDate && (
                                    <div
                                        className="invalid-feedback"
                                        style={{ display: "block" }}
                                    >
                                        {updateInputError?.endDate}
                                    </div>
                                )}
                            </div> */}
                            <div className="col-12 col-md-6">

                                <label for="selections_example" className="form-label">City</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    onBlur={() => {
                                        setTimeout(() => {
                                            setSuggestedCities([])
                                        }, 500)
                                    }
                                    }
                                    placeholder="Type a city name"
                                />
                                <div style={{
                                    width: "100%",
                                    position: "relative"
                                }}>
                                    {suggestedCities?.length > 0 && <ul style={{
                                        position: "absolute",
                                        background: "#fff",
                                        border: "solid 1px #ddd",
                                        maxHeight: "150px",
                                        overflow: "auto",
                                        listStyle: "none",
                                        padding: "10px",
                                        width: "100%"
                                    }}>
                                        {suggestedCities?.map(city => (
                                            <li key={city.cityId} onClick={() => handleCityClick(city)}>
                                                {city.cityName}
                                            </li>
                                        ))}
                                    </ul>}
                                </div>

                                {updateInputError?.cityName && (
                                    <div
                                        className="invalid-feedback"
                                        style={{ display: "block" }}
                                    >
                                        {updateInputError?.cityName}
                                    </div>
                                )}
                            </div>
                            <div className="col-12 col-sm-6">
                                <label for="autocomplete_google" className="form-label">
                                    Address
                                </label>
                                <input type="text" id="autocomplete_google" defaultValue={googleAddress?.address} placeholder="Address *" className={`form-control ${updateInputError?.address ? "is-invalid" : ""
                                    }`} onChange={(e) => {
                                        setGoogleAddress({
                                            ...googleAddress,
                                            address: '',
                                            latitude: 0,
                                            longitude: 0,
                                        })
                                    }} />
                                {updateInputError?.address && (
                                    <div
                                        className="invalid-feedback"
                                        style={{ display: "block" }}
                                    >
                                        {updateInputError?.address}
                                    </div>
                                )}
                            </div>

                            <div className="col-12 col-md-6">
                                <label for="address_url" className="form-label">
                                    Street Address
                                </label>
                                <input
                                    type="text"
                                    className={`form-control ${updateInputError?.streetAddress ? "is-invalid" : ""
                                        }`}
                                    id="address_url"
                                    value={detailsUpdate?.streetAddress || ""}
                                    name="streetAddress"
                                    onChange={updateInputChange}
                                />
                                {updateInputError?.streetAddress && (
                                    <div
                                        className="invalid-feedback"
                                        style={{ display: "block" }}
                                    >
                                        {updateInputError?.streetAddress}
                                    </div>
                                )}
                            </div>

                            {/* <div className="col-12 col-md-6">
                                <label for="portfolioLink_url" className="form-label">
                                    Portfolio Link
                                </label>
                                <div className="input-group has-validation">
                                    <input
                                        type="url"
                                        className={`form-control ${updateInputError?.portfolioLink ? "is-invalid" : ""
                                            }`}
                                        id="portfolioLink_url"
                                        value={detailsUpdate?.portfolioLink || ""}
                                        name="portfolioLink"
                                        onChange={updateInputChange}
                                    />
                                    {updateInputError?.portfolioLink && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {updateInputError?.portfolioLink}
                                        </div>
                                    )}
                                </div>
                            </div> */}
                            {/* <div className="col-12 col-md-6">
                                <label for="eventLink_url" className="form-label">
                                    Event Link
                                </label>
                                <div className="input-group has-validation">
                                    <input
                                        type="url"
                                        className={`form-control ${updateInputError?.eventLink ? "is-invalid" : ""
                                            }`}
                                        id="eventLink_url"
                                        value={detailsUpdate?.eventLink || ""}
                                        name="eventLink"
                                        onChange={updateInputChange}
                                    />
                                    {updateInputError?.eventLink && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {updateInputError?.eventLink}
                                        </div>
                                    )}
                                </div>
                            </div> */}
                            {/* <div className="col-12 col-md-6">
                                <label for="cardLink_url" className="form-label">
                                    Card Link
                                </label>
                                <div className="input-group has-validation">
                                    <input
                                        type="url"
                                        className={`form-control ${updateInputError?.cardLink ? "is-invalid" : ""
                                            }`}
                                        id="cardLink_url"
                                        value={detailsUpdate?.cardLink || ""}
                                        name="cardLink"
                                        onChange={updateInputChange}
                                    />
                                    {updateInputError?.cardLink && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {updateInputError?.cardLink}
                                        </div>
                                    )}
                                </div>
                            </div> */}
                            <div className="col-12 col-md-6">
                                <label for="website_url" className="form-label">
                                    Website Url
                                </label>
                                <div className="input-group has-validation">
                                    <input
                                        type="url"
                                        className={`form-control ${updateInputError?.website ? "is-invalid" : ""
                                            }`}
                                        id="website_url"
                                        value={detailsUpdate?.website || ""}
                                        name="website"
                                        onChange={updateInputChange}
                                    />
                                    {updateInputError?.website && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {updateInputError?.website}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="col-12 col-md-6">
                                <label for="facebook_url" className="form-label">
                                    Facebook Url
                                </label>
                                <div className="input-group has-validation">
                                    <input
                                        type="url"
                                        className={`form-control ${updateInputError?.facebookLink ? "is-invalid" : ""
                                            }`}
                                        id="facebook_url"
                                        value={detailsUpdate?.facebookLink || ""}
                                        name="facebookLink"
                                        onChange={updateInputChange}
                                    />
                                    {updateInputError?.facebookLink && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {updateInputError?.facebookLink}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="col-12 col-md-6">
                                <label for="instagram_url" className="form-label">
                                    Instagram Url
                                </label>
                                <div className="input-group has-validation">
                                    <input
                                        type="url"
                                        className={`form-control ${updateInputError?.instagramLink ? "is-invalid" : ""
                                            }`}
                                        id="instagram_url"
                                        value={detailsUpdate?.instagramLink || ""}
                                        name="instagramLink"
                                        onChange={updateInputChange}
                                    />
                                    {updateInputError?.instagramLink && (
                                        <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                        >
                                            {updateInputError?.instagramLink}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <label for="category" className="form-label">
                                    Category
                                </label>

                                <select
                                    className={`form-select ${updateInputError?.categoryId ? "is-invalid" : ""
                                        }`}
                                    id="category"
                                    required
                                    value={detailsUpdate?.categoryId || ""}
                                    name="categoryId"
                                    onChange={updateInputChange}
                                >
                                    <option value="" disabled selected>
                                        Choose Category...
                                    </option>
                                    {category?.map((res, index) => (
                                        <option key={index} value={res?.categoryId}>
                                            {res?.categoryText}
                                        </option>
                                    ))}
                                </select>
                                {updateInputError?.categoryId && (
                                    <div
                                        className="invalid-feedback"
                                        style={{ display: "block" }}
                                    >
                                        {updateInputError?.categoryId}
                                    </div>
                                )}
                            </div>{" "}
                            <div className="col-12 col-md-6" id="catagory-heading">
                                <span>Sub Category*</span>
                                <div className="catagory-list">
                                    {tagsList?.map((res, index) => (
                                        <div className="catagory-group" key={index}>
                                            <input
                                                autoComplete="off"

                                                type="checkbox"
                                                id={"checkbox" + index}
                                                checked={detailsUpdate?.selectedTags?.includes(res?.id)}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setDetailsUpdate({
                                                            ...detailsUpdate,
                                                            selectedTags: [
                                                                ...detailsUpdate?.selectedTags,
                                                                res?.id,
                                                            ],
                                                        });

                                                    } else {
                                                        setDetailsUpdate({
                                                            ...detailsUpdate,
                                                            selectedTags: detailsUpdate?.selectedTags.filter(
                                                                (element) => element !== res?.id
                                                            ),
                                                        });

                                                    }
                                                }}
                                            />
                                            <label for={"checkbox" + index}>{res?.name} </label>
                                        </div>
                                    ))}
                                </div>
                                {updateInputError?.selectedTags && (
                                    <div
                                        className="invalid-feedback"
                                        style={{ display: "block" }}
                                    >
                                        {updateInputError?.selectedTags}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className=" mt-3">
                        <button type="button" className="btn btn-primary" onClick={() => updateData()} style={{ marginRight: "20px" }}>Update</button>
                        <button style={{ marginRight: "20px" }} type="button" className="btn btn-primary" onClick={() => setDisablePopUp(true)}>{detailsUpdate?.expiredOrDisabled ? 'Disabled' : 'Enabled'}</button>
                        <button className="btn btn-primary" type="button"
                            onClick={() => setAddCampain(true)}
                        >Add Campaign</button>
                    </div>
                </div>
            </div>
            {addCampain &&
                <SearchAddCampainPopUp onClose={() => {
                    setAddCampain(false)
                }} details={{
                    "adminUserId": id,
                    "sessionToken": sessionToken,
                    "cardAction": "VIEW_BUSINESS",
                    "cardId": data?.userBusinessId,
                    "cardType": "BUSINESS",
                    "countryCode": "91",
                    "categoryId": data?.businessCategory?.id,
                    "phoneNumber": data?.businessContactNumber,
                    "productImage": data?.businessImageLocation,


                }} />}
            <ToastContainer autoClose={3000} />

            {loading && <FullPageLoading />}
            {disablePopUp &&
                <ConformPopUp
                    onClose={() => setDisablePopUp(false)}
                    submit={() => {
                        setDisablePopUp(false)
                        disableEnable()
                    }}
                    title=""
                    textMsg={`Are you sure you want to ${detailsUpdate?.expiredOrDisabled ? 'Disabled' : 'Enabled'} this Business`}
                />
            }
            {updatePopUp &&
                <ConformPopUp
                    onClose={() => setUpdatePopUp(false)}
                    submit={() => {
                        setUpdatePopUp(false)
                        updateData2()
                    }}
                    title=""
                    textMsg={`Are you sure you want to Update this Business`}
                />
            }
        </div>
    )
}

export default SearchEdit;